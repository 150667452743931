import React, {useEffect, useState} from "react";
// import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import {useSelector, useDispatch} from "react-redux";
import {fetchorders, submitOrders, saveOrders} from "../ordersCrud";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {cartCountActions} from "../../../../../redux/reducers/cartCount";
import Stripe from "react-stripe-checkout";
import Input from '@material-ui/core/Input';
import axios from 'axios';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OrderSummary from "../OrderSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ListSubheader,
    TextField,
    Collapse,
    Switch
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}));

function ConfirmationPage(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const [orders,setOrders] = useState([]);
    const [currOrder,setCurrOrder] = useState(null);
    const [currOrderStatus,setCurrOrderStatus] = useState(null);
    const [orderFields,setOrderFields] = useState([]);
    const [isOrdersFetched, setIsOrdersFetched] = useState(false)
    const [isCartEmpty, setIsCartEmpty] = useState(false)
    const [messageBO, setMessageBO] = useState("");
    const [openBO, setOpenBO] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [isOrdersValid, setIsOrdersValid] = useState(false)
    const [isAllSubmit, setIsAllSubmit] = useState(false)
    const [isSelectedSubmit, setIsSelectedSubmit] = useState(false)
    const [isOrderSummaryClicked, setIsOderSummaryClicked] = useState(false)
    const [isOrderUpdated, setIsOrderUpdated] = useState(false)
    const [isSubmitCompleted, setIsSubmitCompleted] = useState(false)
    const [clickedOrder, setClickedOrder] = useState({})
    const [defaultDate, setDefaultDate] = useState("");

    const [selectedOrders,setSelectedOrders] = useState([]);
    const [invalidSelectedOrders,setInvalidSelectedOrders] = useState([]);
    const [invoice,setInvoice] = useState(null);

    const [openCW, setOpenCW] = useState(false);
    const [catchWeight, setCatchWeight] = useState("")
    const [catchWeightProduct, setCatchWeightProduct] = useState(null)
    const [catchWeightOrder, setCatchWeightOrder] = useState(null)

    const [expanded, setExpanded] = useState("");

    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setOpenBO(false)
        setCurrOrder(null)
        setInvalidSelectedOrders([])
        setIsSelectedSubmit(false)
        setIsAllSubmit(false)
        if(isOrdersValid && !currOrder && isSubmitCompleted){
            dispatch(cartCountActions.getProductCount(accessToken, user.id))
            props.history.push('/orders');
        }
    }

    function handleCloseBO() {
        setOpenBO(false);
    }

    function handleCloseCW() {
        setOpenCW(false);
    }

    function handleClickOpenBO() {
        setOpenBO(true);
    }

    const handleAccordionChange = (panel) => (event, newExpanded) => {

        setExpanded(newExpanded ? panel : "")

    };

    const handleSubmitOrder = async (isAdminOverride = false) => {

        setIsAllSubmit(true)
        let ordersToSubmit = []
        let ordersToSave = []

        let isOrdersValid = true

        orders.map((order, index) => {
            let orderProducts = []

            order.products.map(product => {
                if(product.listPrice || product.listPrice == ''){
                    if(product.listPrice == ''){
                        isOrdersValid = false
                    }
                } else{
                    if(product.price == ''){
                        isOrdersValid = false
                    }
                }
                if(product._id !== process.env.REACT_APP_FUEL_ID){
                    if(product.amount > product.qtyOnHand){
                        isOrdersValid = false
                    }
                }
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })

            if(orderProducts.length > 0){
                ordersToSubmit.push({
                    ...order,
                    products : orderProducts,
                    status: 'Submitted',
                    ...orderFields[index]
                })
            } else {
                ordersToSave.push({
                    orderId : order._id,
                    products : orderProducts,
                    ...orderFields[index]
                })
            }
        })

        ordersToSubmit.forEach(order => {
            if(order.invoiceDate && order.shipDate && order.invoiceDate !== '' && order.shipDate !== ''){

            } else{
                isOrdersValid = false
                setInvalidSelectedOrders(oldInvalidSelectedOrders => [...oldInvalidSelectedOrders, order])
            }
        })

        setIsOrdersValid(isOrdersValid)

        if(isOrdersValid){
            if(ordersToSubmit.length > 0){
                // console.log("Orders to submit: ", ordersToSubmit)
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                }).catch(e=>{console.log(e)})
            }
            if(ordersToSave.length > 0){
                // console.log("Orders to save: ", ordersToSave)
                await saveOrders(ordersToSave, accessToken).then(response =>{
                }).catch(e=>{console.log(e)})
            }
            setIsSubmitCompleted(true)
            setDialogMessage("Your order has been submited.")
            handleClickOpen()
        } else{
            if(isAdminOverride){
                if(ordersToSubmit.length > 0){
                    // console.log("Orders to submit: ", ordersToSubmit)
                    await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                    }).catch(e=>{console.log(e)})
                }
                if(ordersToSave.length > 0){
                    // console.log("Orders to save: ", ordersToSave)
                    await saveOrders(ordersToSave, accessToken).then(response =>{
                    }).catch(e=>{console.log(e)})
                }
                setDialogMessage("Your order has been submited.")
                setIsSubmitCompleted(true)
                setIsOrdersValid(true)
                setSelectedOrders([])
                handleClickOpen()
            } else{
                setDialogMessage("To submit, orders must have an invoice date and a ship date. All unit prices must be 0 or greater. " +
                                "Not enough items to fulfill an order, except for fuel, amount to order must be less than QoH. Review the orders.")
                handleClickOpen()
            }
        }
    }

    const handleSubmitSelectedOrders = async (isAdminOverride = false) => {

        setIsSelectedSubmit(true)
        let ordersToSubmit = []
        let isOrdersValid = true

        const ordersSelectedToSubmit = orders.filter(curr => selectedOrders.includes(curr._id))

        ordersSelectedToSubmit.map((order, index) => {
            let orderProducts = []
            const currOrderFields = orderFields.find(curr => curr._id == order._id)

            order.products.map(product => {
                if(product.listPrice || product.listPrice === ''){
                    if(product.listPrice === '' || product.listPrice < 0){
                        isOrdersValid = false
                    }
                } else{
                    if(product.price === '' || product.price < 0){
                        isOrdersValid = false
                    }
                }
                if(product._id !== process.env.REACT_APP_FUEL_ID){
                    if(product.amount > product.qtyOnHand){
                        isOrdersValid = false
                    }
                }
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })

            if(orderProducts.length > 0){
                ordersToSubmit.push({
                    ...order,
                    products : orderProducts,
                    status: 'Submitted',
                    ...currOrderFields
                })
            } 
        })

        ordersToSubmit.forEach(order => {
            console.log("Validating orders: ", order)
            if(order.invoiceDate && order.shipDate && order.invoiceDate !== '' && order.shipDate !== ''){

            } else{
                isOrdersValid = false
                setInvalidSelectedOrders(oldInvalidSelectedOrders => [...oldInvalidSelectedOrders, order])
            }
        })

        setIsOrdersValid(isOrdersValid)

        if(isOrdersValid && ordersToSubmit.length > 0){
            
            await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
            }).catch(e=>{console.log(e)})
            setIsSubmitCompleted(true)
            setDialogMessage("Your order has been submited.")
            handleClickOpen()
        } else{
            if(isAdminOverride){
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                    setIsSubmitCompleted(true)
                    setDialogMessage("Your order has been submited.")
                    setIsOrdersValid(true)
                    setSelectedOrders([])
                    handleClickOpen()
                    
                }).catch(e=>{console.log(e)})
            } else{
                setDialogMessage("To submit, orders must have an invoice date and a ship date. All unit prices must be 0 or greater. " +
                                "Not enough items to fulfill an order, except for fuel, amount to order must be less than QoH. Review the orders.")
                handleClickOpen()
            }
        }
    }

    const handleSubmitSingleBackOrder = (order) => {
        setCurrOrder(order)
        setMessageBO("Are you sure you want to submit as Back Order.")
        setCurrOrderStatus("Back Order")
        setOpenBO(true)
    }

    const handleSubmitSingleQuote = (order) => {
        setCurrOrder(order)
        setMessageBO("Are you sure you want to submit as Quote.")
        setCurrOrderStatus("Quote")
        setOpenBO(true)
    }

    const handleSubmitSingleOrder = async (order, status, isAdminOverride = false) => {

        let isOrdersValid = true
        let isCatchWeightValid = true
        let ordersToSubmit = []

        let currOrderFields = orderFields.find(curr => curr._id == order._id)
        let orderProducts = []

        let invalidMessage = ""
        let outOfStockItem = []
        let invalidCatchWeightItems = []

        order.products.map(product => {
            if(product._id !== process.env.REACT_APP_FUEL_ID){
                if(product.amount > product.qtyOnHand){
                    isOrdersValid = false
                    outOfStockItem.push(product.name)
                    // invalidMessage += "Not enough items to fulfill order, except for fuel, amount to order must be less than QoH. "
                    setCurrOrder(order)
                }
            }
            if(product.listPrice || product.listPrice === ''){
                if(product.listPrice === '' || product.listPrice < 0){
                    isOrdersValid = false
                    invalidMessage += "All unit price must be 0 or greater. "
                    setCurrOrder(null)
                }
            } else{
                if(product.price === '' || product.price < 0){
                    isOrdersValid = false
                    invalidMessage += "All unit price must be 0 or greater. "
                    setCurrOrder(null)
                }
            }
            
            if(currOrderFields.shipVia == "HOME" && product.isCatchWeight){
                if(product.catchWeights && product.catchWeights.length < product.amount){
                    
                    isCatchWeightValid = false
                    invalidCatchWeightItems.push(product.name)
                } else if(!product.catchWeights){
                    
                    isCatchWeightValid = false
                    invalidCatchWeightItems.push(product.name)
                }
            }

            if(product.amount > 0){
                orderProducts.push(product)
            }
        })

        if(orderProducts.length > 0){
            ordersToSubmit.push({
                ...order,
                user: user,
                status: status,
                products : orderProducts,
                ...currOrderFields
            })
        } 

        if(ordersToSubmit[0].invoiceDate && ordersToSubmit[0].shipDate && ordersToSubmit[0].invoiceDate !== '' && ordersToSubmit[0].shipDate !== ''){

        } else{
            isOrdersValid = false
            invalidMessage += "To submit, order must have an invoice date and a ship date. "
            setCurrOrder(null)
        }

        if(outOfStockItem.length > 0){
            invalidMessage += "Not enough inventory for the following items to fulfill order: " + outOfStockItem.join(", ")
        }

        if(!isCatchWeightValid){
            isOrdersValid = false

            invalidMessage += "All catch weight items must register weights! Items missing weights: " + invalidCatchWeightItems.join(", ")
            setCurrOrder(null)
        }

        setIsOrdersValid(isOrdersValid)

        if(isOrdersValid){
            await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                console.log("Response: ", response)
                setIsSubmitCompleted(true)
                setDialogMessage("Your order has been submited.")
                setIsOrdersValid(true)
                handleClickOpen()
                setCurrOrder(null)
                
                if(response.data){
                    setInvoice(response.data)
                }
                
            }).catch(e=>{console.log(e)})
        } else{
            if(isAdminOverride){
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                    console.log("Response: ", response)
                    setIsSubmitCompleted(true)
                    setDialogMessage("Your order has been submited.")
                    setIsOrdersValid(true)
                    handleClickOpen()
                    setCurrOrder(null)

                    if(response.data){
                        setInvoice(response.data)
                    }
                    
                }).catch(e=>{console.log(e)})
            } else{
                setDialogMessage(invalidMessage)
                handleClickOpen()
            }
        }
    }

    const handleSaveOrders = () => {
        let ordersToSubmit = []

        orders.map((order, index) => {
            let orderProducts = []

            order.products.map(product => {
                if(product.listPrice || product.listPrice === ''){
                
                    if(product.listPrice === '' || product.listPrice < 0){
                    
                        isOrdersValid = false
                    }
                } else{
                    
                    if(product.price === '' || product.price < 0){
                        
                        isOrdersValid = false
                    }
                }
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })
            
            ordersToSubmit.push({
                orderId : order._id,
                products : orderProducts,
                ...orderFields[index]
            })
        })
        
        console.log(ordersToSubmit)

        saveOrders(ordersToSubmit, accessToken).then(response =>{
            window.location.reload()
        }).catch(e=>{console.log(e)})
    }

    const handlePrintInvoice = async () => {

        const salesTaxCode = invoice.company[0].salesTaxCode
        let municipalTaxSum = 0
        let stateTaxSum = 0

        invoice.products.forEach(currProduct => {
            if(currProduct.isTaxable){
                if(salesTaxCode == "SALES PR"){
                    stateTaxSum += (currProduct.price * currProduct.amount) * 0.105
                    municipalTaxSum += (currProduct.price * currProduct.amount) * 0.01
                } else if(salesTaxCode == "10.5C"){
                    stateTaxSum += (currProduct.price * currProduct.amount) * 0.105
                }
            }
        })

        let totalAmount = 0
        const salesOrderProductsData = invoice.products.map(currItem => {
            let itemPrice = currItem.listPrice ? currItem.listPrice : currItem.price
            let itemTotalAmount = 0

            if(invoice.shipVia == "HOME" && currItem.isCatchWeight){
                const totalWeight = currItem.catchWeights?.length > 0 ? currItem?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
                itemTotalAmount = (itemPrice * totalWeight)
                totalAmount += itemPrice * totalWeight;
            } else{
                itemTotalAmount = (itemPrice * currItem.amount)
                totalAmount += itemPrice * currItem.amount
            }
            
            return {
                name: currItem.name,
                category: currItem.category,
                sku: currItem.sku,
                unitPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                units: currItem.amount,
                itemPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                totalAmount: itemTotalAmount.toFixed(2),
                isCatchWeight: currItem.isCatchWeight,
                catchWeights: currItem.catchWeights ? currItem.catchWeights : [],
            }
        })
        const salesOrderData = {
            storeName: invoice.companyName,
            submissionDate: invoice.submissionDate,
            requestedDeliveryDate: invoice.shipDate,
            routeName: invoice.routes[0].name,
            orderNumber: invoice.orderNumber,
            salesRep: invoice.user[0].fullName,
            products: salesOrderProductsData,
            clientNumber: invoice.company[0].sageCustomerId,
            paymentTerm: invoice.company[0].dueDays,
            supplierName: invoice.supplier[0].entityName,
            supplierDescription: invoice.supplier[0].description,
            supplierPhone1: invoice.supplier[0].phoneNumber,
            supplierPhone2: invoice.supplier[0].phoneNumber2,
            supplierFax: invoice.supplier[0].fax,
            supplierAddress: `${invoice.supplier[0].physicalAddress}, ${invoice.supplier[0].physicalCity}, Puerto Rico, ${invoice.supplier[0].physicalZipcode}`,
            supplierDisclaimer: invoice.supplier[0].disclaimer,
            totalAmount: totalAmount.toFixed(2),
            sageInvoiceNumber: invoice.sageInvoiceNumber ? invoice.sageInvoiceNumber : false,
            salesTaxAmount: invoice.salesTaxAmount ? invoice.salesTaxAmount : false,
            totalCostTax: (totalAmount + stateTaxSum + municipalTaxSum).toFixed(2),
            comment: invoice.comment,
            stateTax: stateTaxSum,
            municipalTax: municipalTaxSum
        }
        
        const supplierTenantId = invoice.supplier[0].tenantId
        const urlPath = process.env.REACT_APP_WMS_API_HTTPS + (process.env.REACT_APP_ENV === 'DEV' ?
            `${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/store_order_endpoint_pdf` :
            `${supplierTenantId}.${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/store_order_endpoint_pdf`
          );

        const response = await axios.post(urlPath, 
        // const response = await axios.post("http://localhost:8003/api/tenants/wahmey/route_records/store_order_endpoint_pdf", 
        salesOrderData, {
            responseType: 'arraybuffer', // Ensure the response type is set to arraybuffer
        });

        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new tab with the PDF
        const newTab = window.open(pdfUrl, '_blank');

        // handleClose()
    }

    useEffect(() =>{
        fetchorders(user.id,accessToken).then(response =>{
            // console.log("Orders", response.data)
            let confirmationOrders = response.data.filter(order => order.products.length > 0)

            let orderFields = []
            if(process.env.REACT_APP_BIOWARE_TENANT == 'biowarebuyer'){

                confirmationOrders.forEach((order, orderIndex) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })

                    const fuelItem = order.products.find(product => product._id == process.env.REACT_APP_FUEL_ID)
                    if(fuelItem){
                        let products = order.products.filter(product => product._id !== fuelItem._id)

                        products.push(fuelItem)

                        const newOrder = {
                            ...order,
                            products : products
                        }

                        confirmationOrders[orderIndex] = newOrder
                    }

                })
            } if(process.env.REACT_APP_MCS_TENANT == user.tenant){
                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        expectedDeliveryDate : order.expectedDeliveryDate ? order.expectedDeliveryDate : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                        proofOfDelivery : order.proofOfDelivery ? order.proofOfDelivery : '',
                        phoneNumber : order.phoneNumber ? order.phoneNumber : '',
                        deliveryPurpose : order.deliveryPurpose ? order.deliveryPurpose : '',
                        contactPerson : order.contactPerson ? order.contactPerson : '',

                    })
                })
            } else {
                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })
                })
            }

            // console.log("Confirmation Orders: ", confirmationOrders)
            setOrderFields(orderFields)
            setOrders(confirmationOrders)

            if(confirmationOrders.length > 0){
                setIsOrdersFetched(true)
            } else {
                setIsCartEmpty(true)
            }
        })
    },[])

    useEffect(() =>{
        fetchorders(user.id,accessToken).then(response =>{
            // console.log("Orders", response.data)
            let confirmationOrders = response.data.filter(order => order.products.length > 0)
            let orderFields = []

            if(process.env.REACT_APP_BIOWARE_TENANT == user.tenant){
                //if(process.env.REACT_APP_BIOWARE_TENANT == 'biowarebuyer'){

                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })

                    const fuelItem = order.products.find(product => product._id == process.env.REACT_APP_FUEL_ID)
                    if(fuelItem){
                        let products = order.products.filter(product => product._id !== fuelItem._id)

                        products.push(fuelItem)

                        const newOrder = {
                            ...order,
                            products : products
                        }

                        confirmationOrders[index] = newOrder
                    }
                })
            } if(process.env.REACT_APP_MCS_TENANT == user.tenant){
                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                        expectedDeliveryDate: order.expectedDeliveryDate ? order.expectedDeliveryDate : '',
                        proofOfDelivery : order.proofOfDelivery ? order.proofOfDelivery : '',
                        deliveryPurpose : order.deliveryPurpose ? order.deliveryPurpose : '',
                        allecInvWareHouse : order.allecInvWareHouse ? order.allecInvWareHouse : '',
                        contactPerson : order.contactPerson ? order.contactPerson : '',
                        phoneNumber : order.phoneNumber ? order.phoneNumber : '',
                    })
                })
            } else {
                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })
                })
            }

            setOrderFields(orderFields)
            setOrders(confirmationOrders)

            if(confirmationOrders.length > 0){
                setIsOrdersFetched(true)
            } else {
                setIsCartEmpty(true)
            }
        })
    },[isOrderUpdated])

    

    const handleProductAmountChange = (orderId, productId, productAmount) => {
        let orderIndex = orders.findIndex(order => order._id === orderId)
        let order = orders.find(order => order._id === orderId)
        let newOrders = orders

        let products = [...order.products]
        let productIndex = products.findIndex(product => product._id === productId)

        const oldTotalAmount = order.totalProductQty
        const oldTotalCost = order.totalCost
        let newTotalAmount = oldTotalAmount
        let newTotalCost = parseFloat(oldTotalCost)

        const product = {...products[productIndex]}
        let productPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price

        if(productPrice > 0){
            if(product.amount < productAmount){
                if(product.isStaticCatchWeight && product.catchWeights){
                    newTotalCost += parseFloat(productPrice) * product.staticCatchWeight
                } else{
                    newTotalCost += parseFloat(productPrice)
                }
                
            } else {
                if(product.isStaticCatchWeight && product.catchWeights){
                    newTotalCost -= parseFloat(productPrice) * product.staticCatchWeight
                } else{
                    newTotalCost -= parseFloat(productPrice)
                }
            }
        }
        if(product.amount < productAmount){
            newTotalAmount += 1
            if(product.isStaticCatchWeight && product.catchWeights){
                product.catchWeights.push(product.staticCatchWeight)
            }
        } else {
            newTotalAmount -= 1
            if(product.isStaticCatchWeight && product.catchWeights){
                product.catchWeights.pop()
            }
        }

        products[productIndex] = {
            ...product,
            amount : productAmount,
        }

        const newOrder = {
            ...order,
            totalProductQty : newTotalAmount,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        newOrders[orderIndex] = newOrder
        
        setOrders([...newOrders])
    }

    const handlePriceChange = (orderId, productId, newPrice) => {
        let orderIndex = orders.findIndex(order => order._id === orderId)
        let order = orders.find(order => order._id === orderId)
        let newOrders = orders

        let products = order.products
        let productIndex = products.findIndex(product => product._id === productId)
        const product = products[productIndex]

        let newPriceParsed = parseFloat(newPrice)

        const oldPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        const oldTotalCost = order.totalCost
        
        let newTotalCost = parseFloat(oldTotalCost)
        let totalWeight = 0

        if(product.isCatchWeight && product.catchWeights){
            totalWeight = product.catchWeights.length > 0 ? product?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
        }
        
        if(oldPrice == ''){
            newTotalCost -= parseFloat(product.amount * 0)
        } else{
            if(product.isCatchWeight && product.catchWeights){
                newTotalCost -= parseFloat(totalWeight * oldPrice)
            } else{
                newTotalCost -= parseFloat(product.amount * oldPrice)
            }
        }   

        if(!isNaN(newPriceParsed)){
            if(product.isCatchWeight && product.catchWeights){
                newTotalCost += parseFloat(totalWeight * newPriceParsed)
            } else{
                newTotalCost += parseFloat(product.amount * newPriceParsed)
            }
        }  

        if(isNaN(newPriceParsed)){
            newPriceParsed = ""
        }

        if(product.listPrice || product.listPrice == ''){
            products[productIndex] = {
                ...products[productIndex],
                listPrice : newPriceParsed,
            }
        } else{
            products[productIndex] = {
                ...products[productIndex],
                price : newPriceParsed,
            }
        }

        const newOrder = {
            ...order,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        newOrders[orderIndex] = newOrder
        
        setOrders([...newOrders])
    }

    function handleRemove(orderId, productId) {
        let orderIndex = orders.findIndex(order => order._id === orderId)
        let order = orders.find(order => order._id === orderId)
        let newOrders = orders

        const productToRemove = order.products.find(product => product._id == productId)
        const productAmount = productToRemove.amount
        let productPrice = (productToRemove.listPrice || productToRemove.listPrice == '') ? productToRemove.listPrice : productToRemove.price

        if(productPrice == ''){
            productPrice = 0
        }

        // if(productToRemove.isCatchWeight){
        //     productPrice = productPrice * productToRemove.avgWeight
        //     productPrice = Math.round( parseFloat(productPrice) * 1e2 ) / 1e2
        // }

        let newTotalCost = parseFloat(order.totalCost)

        if(productToRemove.isCatchWeight && productToRemove.catchWeights){
            let totalWeight = productToRemove.catchWeights.length > 0 ? productToRemove?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
            newTotalCost -= totalWeight * productPrice
        } else{
            newTotalCost -= productAmount * productPrice
        }

        

        let newTotalAmount = order.totalProductQty
        newTotalAmount -= productAmount

        let products = order.products.filter(product => product._id !== productId)

        const newOrder = {
            ...order,
            totalProductQty : newTotalAmount,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }
        
        if(products.length > 0){
            newOrders[orderIndex] = newOrder
        } else{
            newOrders = orders.filter(currOrder => currOrder._id !== orderId)
        }
        
        setOrders([...newOrders])
    }

    function handleCatchWeightClick(orderId, productId) {
        const currOrderIndex = orders.findIndex(order => order._id === orderId)
        let currOrder = {...orders[currOrderIndex]}
        let newOrders = [...orders]

        const currProductIndex = currOrder.products.findIndex(product => product._id == productId)
        let currProduct = {...currOrder.products[currProductIndex]}

        let catchWeights = []
        if(typeof currProduct.catchWeights == "undefined"){
            currProduct.catchWeights = catchWeights
        }

        // let totalWeight = 0
        // if(catchWeights.length > 0){
        //     totalWeight = catchWeights.reduce((total, currWeight) => total + parseFloat(currWeight))
        // }

        // let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
        // if(currProductPrice == ''){
        //     currProductPrice = 0
        // }
        // const oldTotalCost = currOrder.totalCost
        // let newTotalCost = oldTotalCost + (currProductPrice * totalWeight)
        
        newOrders[currOrderIndex].products[currProductIndex] = currProduct
        // newOrders[currOrderIndex].totalCost = parseFloat(newTotalCost.toFixed(2))

        setOrders(newOrders)
        setCatchWeightProduct({...currProduct})
        setCatchWeightOrder({...currOrder})

        setOpenCW(true);
    }

    function handleAddCatchWeight() {
        
        if(catchWeightProduct.catchWeights && catchWeightProduct.catchWeights.length >= catchWeightProduct.amount){
            // console.log("Don't do Shit!")
        } else if(typeof catchWeight === "number" && catchWeight > 0){
            const currOrderIndex = orders.findIndex(order => order._id === catchWeightOrder._id)
            let currOrder = {...orders[currOrderIndex]}
            let newOrders = [...orders]

            const currProductIndex = currOrder.products.findIndex(product => product._id == catchWeightProduct._id)
            let currProduct = {...currOrder.products[currProductIndex]}
            
            if(currProduct.catchWeights){
                currProduct.catchWeights.push(catchWeight)
            } else{
                currProduct.catchWeights = [catchWeight]
            }

            let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
            if(currProductPrice == ''){
                currProductPrice = 0
            }
            const oldTotalCost = currOrder.totalCost
            let newTotalCost = oldTotalCost + (currProductPrice * catchWeight)

            newOrders[currOrderIndex].products[currProductIndex] = currProduct
            newOrders[currOrderIndex].totalCost = parseFloat(newTotalCost.toFixed(2))

            setOrders(newOrders)
            setCatchWeightOrder(currOrder)
            setCatchWeightProduct(currProduct)
            setCatchWeight("")
        }
    }

    function handleRemoveCatchWeight(cw) {
        
        if(catchWeightProduct.catchWeights && catchWeightProduct.catchWeights.length > 0){
            
            const currOrderIndex = orders.findIndex(order => order._id === catchWeightOrder._id)
            let currOrder = {...orders[currOrderIndex]}
            let newOrders = [...orders]

            const currProductIndex = currOrder.products.findIndex(product => product._id == catchWeightProduct._id)
            let currProduct = {...currOrder.products[currProductIndex]}
            
            
            const currrCatchWeightIndex = currProduct.catchWeights.findIndex(catchWeight => catchWeight == cw)
            let catchWeightRemoved = 0
            if (currrCatchWeightIndex > -1) {
                catchWeightRemoved = currProduct.catchWeights[currrCatchWeightIndex]
                currProduct.catchWeights.splice(currrCatchWeightIndex, 1)
            }

            let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
            if(currProductPrice == ''){
                currProductPrice = 0
            }
            const oldTotalCost = currOrder.totalCost
            let newTotalCost = oldTotalCost + (currProductPrice * catchWeightRemoved)

            newOrders[currOrderIndex].products[currProductIndex] = currProduct
            newOrders[currOrderIndex].totalCost = parseFloat(newTotalCost.toFixed(2))

            setOrders(newOrders)
            setCatchWeightOrder(currOrder)
            setCatchWeightProduct(currProduct)
        }
    }

    const handleToken = (totalAmount, token) => {
        try {
            axios.post(process.env.REACT_APP_API_URL + "/stripe/pay", {
                token: token.id,
                amount: totalAmount
            }).then((result) => {
                // Run code after successful payment
                handleSubmitOrder()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const tokenHandler = (token) => {
        handleToken(10, token)


    }

    const handleCommentChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].comment = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleCustomerPoChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].customerPo = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleOrderExpectedDeliveryDateChange = (e, index) => {
        let newOrderFields = [...orderFields]
        newOrderFields[index].expectedDeliveryDate = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleOrderShipToChange = (index, e) => {
        let newOrderFields = [...orderFields]
        newOrderFields[index].allecInvWareHouse = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleDeliveryPurposeChange = (index, e) => {
        let newOrderFields = [...orderFields]
        newOrderFields[index].deliveryPurpose = e.target.value

        setOrderFields(newOrderFields)
    };

    const handleContactPerson = (index, e) => {
        let newOrderFields = [...orderFields]
        newOrderFields[index].contactPerson = e.target.value

        setOrderFields(newOrderFields)
    };

    const handlePhoneNumber = (index, e) => {
        let newOrderFields = [...orderFields]
        newOrderFields[index].phoneNumber = e.target.value

        setOrderFields(newOrderFields)
    };

    const handleProofOfDeliveryChange = (index, e) => {
        let newOrderFields = [...orderFields]
        newOrderFields[index].proofOfDelivery = e.target.checked

        setOrderFields(newOrderFields)
    };

    const handleDefaultDateChange = (e) => {
        
        let newOrderFields = [...orderFields]

        for(let i = 0; i < newOrderFields.length; i++){
            newOrderFields[i].invoiceDate = e.target.value
            newOrderFields[i].shipDate = e.target.value
        }

        setOrderFields(newOrderFields)
    }

    const handleInvoiceDateChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].invoiceDate = e.target.value

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            newOrderFields[index].shipDate = e.target.value
        }

        setOrderFields(newOrderFields)
    }

    const handleShipDateChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].shipDate = e.target.value

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            newOrderFields[index].invoiceDate = e.target.value
        }

        setOrderFields(newOrderFields)
    }

    const handleShipViaChange = (e, index, orderId) => {
        console.log("Changing ship via to: ", e.target.value)
        let newOrderFields = [...orderFields]
        newOrderFields[index].shipVia = e.target.value

        if(e.target.value == "HOME"){
            const currOrderIndex = orders.findIndex(order => order._id === orderId)
            let currOrder = {...orders[currOrderIndex]}
            let newOrders = [...orders]
            let newTotalCost = currOrder.totalCost

            console.log("NTC: ", newTotalCost)
            
            let resultProducts = currOrder.products.map(item => {

                let currProduct = {...item}
                
                if(currProduct.isStaticCatchWeight && !Array.isArray(currProduct?.catchWeights)){
                    let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
                    let catchWeights = []

                    for(let i = 0; i < currProduct.amount; i++){
                        catchWeights.push(currProduct.staticCatchWeight)
                    }
    
                    const totalWeight = (catchWeights.reduce((total, currWeight) => total + parseFloat(currWeight)))
                    currProduct.catchWeights = catchWeights
                    newTotalCost += totalWeight * currProductPrice
                } 

                return currProduct
            })

            newOrders[currOrderIndex].totalCost = parseFloat(newTotalCost.toFixed(2))
            newOrders[currOrderIndex].products = resultProducts

            setOrders(newOrders)
        }

        setOrderFields(newOrderFields)
    }

    const handleOrderClick = (order) => {
        console.log("Order clicked: ", order)
        
        setClickedOrder(order)
        setIsOderSummaryClicked(true)
    }
    
    const handleOrderSelect = (order) => {
        
        const orderFound = selectedOrders.find(currOrderId => currOrderId == order._id)
        if(orderFound){
            const newSelectedOrders = selectedOrders.filter(currOrderId => currOrderId != order._id)
            setSelectedOrders(newSelectedOrders)
        } else{
            setSelectedOrders(oldSelectedOrders => [...oldSelectedOrders, order._id])
        }
    }

    return (
        <>
        
            {isOrdersFetched ? 
                isOrderSummaryClicked ? 
                <OrderSummary currOrder={clickedOrder} setIsOderSummaryClicked={setIsOderSummaryClicked} 
                    setIsOrderUpdated={setIsOrderUpdated} isOrderUpdated={isOrderUpdated} />
                :
                <div>
                    {orders.map((order, index)=> (
                        <ExpansionPanel square expanded={expanded === 'panel' + index} onChange={handleAccordionChange('panel' + index)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls={'panel-content-' + index}
                                id={'panel-header-' + index}
                                >
                                <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input type="checkbox" checked={selectedOrders.find(currOrder => currOrder == order._id)} name="" onClick={() => handleOrderSelect(order)} />
                                    <span></span>
                                </label>
                                <Typography className={classes.heading}>
                                    {`Order #${order?.orderNumber}`}
                                </Typography>
                                <Typography className={classes.secondaryHeading}>{order?.companyName}</Typography>
                                
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="flex-row-fluid ml-lg-8">
                                    <div className="">
                                        <div className="card-body p-0">
                                            <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                                                <div className="col-md-10">
                                                    <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        {
                                                                            user.isSeller ?
                                                                                <th className="pl-4 font-weight-boldest text-uppercase">Customer:</th>
                                                                            :
                                                                                <th className="pl-4 font-weight-boldest text-uppercase">Company:</th>
                                                                        }
                                                                        <th className="font-weight-boldest text-uppercase">Ship To:</th>
                                                                        {
                                                                            user.isSeller ?
                                                                                <th className="font-weight-boldest text-uppercase">Sales Rep:</th>
                                                                                :
                                                                                <th className="font-weight-boldest text-uppercase">Ordering To:</th>
                                                                        }

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="font-weight-boldest">
                                                                        <td className="pl-4 d-flex">
                                                                            {/* <h6 className="font-weight-bolder mb-3">Delivery Address:</h6> */}
                                                                            <div className="text-dark-50">
                                                                                <div>{order.company[0].businessName}</div>
                                                                                {/*<div>Melbourne 3000, VIC, Australia</div>*/}
                                                                            </div>
                                                                        </td>
                                                                        <td >
                                                                            {/* <h6 className="font-weight-bolder mb-3">Delivery Address:</h6> */}
                                                                            <div className="text-dark-50">
                                                                                <div>{order.location[0].locationName}</div>
                                                                                <div>{order.location[0].locationAddress1} {order.location[0].locationAddress2}</div>
                                                                                <div>{order.location[0].locationCity}, {order.location[0].locationZipcode}</div>
                                                                                {/*<div>Melbourne 3000, VIC, Australia</div>*/}
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            user.isSeller ?
                                                                                <td className="text-dark-50">{order.user[0].fullName}</td>
                                                                                :
                                                                                <td className="text-dark-50">{order.supplier[0].entityName}</td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="my-3 btn btn-primary w-100" onClick={() => handleOrderClick(order)} >Edit Order</button>
                                                    <div>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                {process.env.REACT_APP_MCS_TENANT === user.tenant ? (
                                                                    <div className="form-group fv-plugins-icon-container">
                                                                        <label htmlFor={"orderComent"}>Special Instructions</label>
                                                                        <textarea
                                                                            value={orderFields[index].comment}
                                                                            onChange={(e) => handleCommentChange(e, index)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            placeholder={"Special Instructions"}
                                                                            rows="3"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"orderComent"}>Comment</label>
                                                                    <textarea
                                                                        value={orderFields[index].comment}
                                                                        onChange={(e) => handleCommentChange(e, index)}
                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                        placeholder={"Comment"}
                                                                        rows="3"
                                                                    />
                                                                </div>
                                                                )}
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"customerPo"}>Customer PO</label>
                                                                    <input
                                                                        value={orderFields[index].customerPo}
                                                                        onChange={(e) => handleCustomerPoChange(e, index)}
                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                        placeholder={"Customer PO"}
                                                                        type="text"
                                                                    />
                                                                </div>

                                                                {process.env.REACT_APP_MCS_TENANT === user.tenant ? (
                                                                    <div>
                                                                        <div className="form-group fv-plugins-icon-container">
                                                                            <label htmlFor={"deliveryPurpose"}>Delivery
                                                                                Purpose</label>
                                                                            <input
                                                                                value={orderFields[index]?.deliveryPurpose || ""}
                                                                                onChange={(e) => handleDeliveryPurposeChange(index, e)}
                                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                placeholder={"Delivery Purpose"}
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                        <div className="form-group fv-plugins-icon-container">
                                                                            <label htmlFor={"contactPerson"}>Contact Person</label>
                                                                            <input
                                                                                value={orderFields[index]?.contactPerson || ""}
                                                                                onChange={(e) => handleContactPerson(index, e)}
                                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                placeholder={"Contact Person"}
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                        <div className="form-group fv-plugins-icon-container">
                                                                            <label htmlFor={"phoneNumber"}>Phone Number</label>
                                                                            <input
                                                                                value={orderFields[index]?.phoneNumber || ""}
                                                                                onChange={(e) => handlePhoneNumber(index, e)}
                                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                placeholder={"Phone Number"}
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                ) : (
                                                                <FormControl variant="filled" style={{ width: "100%" }}>
                                                                    <InputLabel>Ship Via</InputLabel>
                                                                    <Select
                                                                        MenuProps={{ autoFocus: false }}
                                                                        value={orderFields[index].shipVia}
                                                                        style={{ minWidth: "200px" }}
                                                                        input={<FilledInput name="shipVia" id="shipVia"
                                                                            onChange={(element) => {
                                                                                handleShipViaChange(element, index, order._id);
                                                                                // setCompany(companies.find(company => company._id == element.target.value))
                                                                                // setLocations(companies.find(company => company._id == element.target.value).locations)
                                                                            } }
                                                                            type="text"
                                                                            className="form-control form-control-solid h-auto" />}>

                                                                        <MenuItem key={order.company[0].shipVia} value={order.company[0].shipVia}>{order.company[0].shipVia == "Our Truck" ? "Delivery" : order.company[0].shipVia == "HOME" ? "Pick Up" : order.company[0].shipVia}</MenuItem>
                                                                        {
                                                                            order.supplier[0].shipViaOptions.map((option,index) =>(
                                                                                order.company[0]?.shipVia == option ?
                                                                                null
                                                                                :
                                                                                <MenuItem key={option} value={option}>{option == "Our Truck" ? "Delivery" : option == "HOME" ? "Pick Up" : option}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                                                    <Input
                                                                        type="date"
                                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                        name="personalBirthDate"
                                                                        value={orderFields[index].invoiceDate}
                                                                        onChange={(e) => handleInvoiceDateChange(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"shipDate"}>Ship Date</label>
                                                                    <Input
                                                                        type="date"
                                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                        name="personalBirthDate"
                                                                        value={orderFields[index].shipDate}
                                                                        onChange={(e) => handleShipDateChange(e, index)}
                                                                    />
                                                                </div>

                                                                {process.env.REACT_APP_MCS_TENANT === user.tenant ? (
                                                                    <>
                                                                        <div className="form-group fv-plugins-icon-container">
                                                                            <label htmlFor={"expectedDeliveryDate"}>Expected
                                                                                Delivery Date</label>
                                                                            <Input
                                                                                type="date"
                                                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                                name="expectedDeliveryDate"
                                                                                value={orderFields[index].expectedDeliveryDate}
                                                                                onChange={(e) => handleOrderExpectedDeliveryDateChange(e, index)}
                                                                            />
                                                                        </div>

                                                                        <FormControl variant="filled" style={{width: "100%"}}>
                                                                            <InputLabel>Ship To</InputLabel>
                                                                            <Select
                                                                                MenuProps={{autoFocus: false}}
                                                                                value={order.allecInvWareHouse}
                                                                                style={{minWidth: "200px"}}
                                                                                onChange={(e) => {
                                                                                    handleOrderShipToChange(index, e);
                                                                                }}
                                                                                input={
                                                                                    <FilledInput
                                                                                        name="allecInvWareHouse"
                                                                                        id="allecInvWareHouse"
                                                                                        type="text"
                                                                                        className="form-control form-control-solid h-auto"
                                                                                    />
                                                                                }
                                                                            >
                                                                                {
                                                                                    order.supplier[0].warehouses?.map((warehouse) => (
                                                                                        <MenuItem
                                                                                            key={warehouse.id}
                                                                                            value={warehouse.id}
                                                                                        >
                                                                                            {warehouse.name}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                        </FormControl>

                                                                        <div>
                                                                            <label htmlFor={"proofOfDeliveryToggle"}>Proof of
                                                                                Delivery</label>
                                                                            <Switch
                                                                                checked={orderFields[index].proofOfDelivery}
                                                                                onChange={(e) => handleProofOfDeliveryChange(index, e)}
                                                                                name="proofOfDeliveryToggle"
                                                                                color="primary"
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="pb-5" data-wizard-type="step-content">
                                                        {/*<h4 className="mb-10 font-weight-bold text-dark">Review your Order and Submit</h4>*/}
                                                        {/* <h6 className="font-weight-bolder mb-3">Delivery Address:</h6>
                                                        <div className="text-dark-50 line-height-lg">
                                                            <div>{order.company[0].businessName}</div>
                                                            <div>{order.location[0].locationAddress}</div>
                                                            <div>{order.location[0].locationCity}, {order.location[0].locationZipcode}</div> */}
                                                            {/*<div>Melbourne 3000, VIC, Australia</div>*/}
                                                        {/* </div> */}
                                                        {/* <div className="separator separator-dashed my-5"></div> */}

                                                        {/* <h6 className="font-weight-bolder mb-3">Order Details:</h6>
                                                        <div>{order.supplier[0].entityName}</div> */}
                                                        <div className="text-dark-50 line-height-lg">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr className='border-bottom'>
                                                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Ordered
                                                                                Items
                                                                            </th>
                                                                            {/* <th className="text-center font-weight-bold text-muted text-uppercase">SKU</th> */}
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Unit
                                                                                Price
                                                                            </th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">QoH</th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Qty To Order</th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Item
                                                                                Price
                                                                            </th>
                                                                            {
                                                                                orderFields[index].shipVia == "HOME" ? 
                                                                                    <th className="text-center font-weight-bold text-muted text-uppercase">
                                                                                        Catch Weight
                                                                                    </th>
                                                                                :
                                                                                    null
                                                                            }
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Total
                                                                                Amount
                                                                            </th>
                                                                            
                                                                            
                                                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Remove</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {order.products.map(product => (
                                                                        <tr className="font-weight-boldest border-bottom-0">
                                                                            <td className="border-top-0 pl-0 py-2 align-items-center">
                                                                                {/* <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                                                    <div className="symbol-label"
                                                                                        style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                                                                </div> */}
                                                                                
                                                                                <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                                                                                    {product.name}
                                                                                </span>
                                                                                <span className="text-muted font-weight-bold d-block text-center">
                                                                                    {product.sku}
                                                                                </span>
                                                                            </td>
                                                                            {/* <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {product.sku}
                                                                            </td> */}
                                                                            
                                                                            <td className="border-top-0 text-right py-2 align-middle">{product.listPrice || product.listPrice == '' ? 
                                                                                    
                                                                                    <input
                                                                                        value={product.listPrice}
                                                                                        onChange={(e) => handlePriceChange(order._id, product._id, e.target.value)}
                                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        style={{minWidth:"125px"}}
                                                                                    />
                                                                                    :
                                                                                    <input
                                                                                        value={product.price}
                                                                                        onChange={(e) => handlePriceChange(order._id, product._id, e.target.value)}
                                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        style={{minWidth:"125px"}}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {product.qtyOnHand}
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                                                                <div className="align-middle">
                                                                                    <button className="btn btn-xs btn-light-primary btn-icon mr-2" disabled={product.amount === 0} onClick={() => {
                                                                                        handleProductAmountChange(order._id, product._id, product.amount - 1)
                                                                                    }}>
                                                                                        <i className="ki ki-minus icon-xs"></i>
                                                                                    </button>
                                                                                    <span className="mr-2 font-weight-bolder align-middle">{product.amount}</span>
                                                                                    <button className="btn btn-xs btn-light-primary btn-icon mr-2" onClick={() => {
                                                                                        handleProductAmountChange(order._id, product._id, product.amount + 1)
                                                                                    }} >
                                                                                        <i className="ki ki-plus icon-xs"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {
                                                                                    product.listPrice && product.listPrice !== '' ?
                                                                                        "$" + product.listPrice
                                                                                    :
                                                                                        "$" + product.price
                                                                                }
                                                                            </td>
                                                                            
                                                                            {
                                                                                orderFields[index].shipVia == "HOME" ? 
                                                                                    product.isCatchWeight ?
                                                                                        <td className="border-top-0 text-center d-flex justify-content-center">
                                                                                            <IconButton  onClick={() => handleCatchWeightClick(order._id, product._id)}
                                                                                            >
                                                                                                <span className={`center svg-icon svg-icon-md ${product.amount == product.catchWeights?.length ? "svg-icon-success" : "svg-icon-danger"}`}>
                                                                                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")} />
                                                                                                </span>
                                                                                            </IconButton>
                                                                                        </td>
                                                                                        :
                                                                                        <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                                                                                            {"N/A"}
                                                                                        </span>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {
                                                                                    product.listPrice && product.listPrice !== '' ?
                                                                                        product.isCatchWeight ? 
                                                                                            "$" + (product.listPrice * (product?.catchWeights?.length > 0 ? product?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0)).toFixed(2)
                                                                                            :
                                                                                            "$" + (product.listPrice * product.amount).toFixed(2)
                                                                                    :
                                                                                        product.isCatchWeight ? 
                                                                                            "$" + (product.price * (product?.catchWeights?.length > 0 ? product?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0)).toFixed(2)
                                                                                            :
                                                                                            "$" + (product.price * product.amount).toFixed(2)
                                                                                }
                                                                            </td>
                                                                            
                                                                            <td className="border-top-0 text-right py-2 align-middle">
                                                                                <InputAdornment position="end">
                                                                                    <IconButton  onClick={() => handleRemove(order._id, product._id)}
                                                                                    >
                                                                                    <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                                                    </span>
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            </td>
                                                                            {/*<td className="text-primary border-top-0 pr-0 py-4 text-right align-middle">$449.00</td>*/}
                                                                        </tr>
                                                                    ))}
                                                                        <tr className="font-weight-boldest border-bottom-0">
                                                                            <td className="pl-0 py-4 d-flex align-items-center">Total</td>
                                                                            <td ></td>
                                                                            <td ></td>
                                                                            <td className="text-center py-4 align-middle">{order.totalProductQty}</td>
                                                                            <td ></td>
                                                                            <td ></td>
                                                                            <td className="text-center py-4 align-middle">{`$${order.totalCost}`}</td>
                                                                            <td ></td>

                                                                        </tr>
                                                                    {/*<tr className='border-top'>*/}
                                                                    {/*    <td colSpan="2"></td>*/}
                                                                    {/*    <td className="font-weight-bolder text-right">Subtotal</td>*/}
                                                                    {/*    <td className="font-weight-bolder text-right pr-0">$1538.00</td>*/}
                                                                    {/*</tr>*/}
                                                                    {/*<tr>*/}
                                                                    {/*    <td colSpan="2" className="border-0 pt-0"></td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder text-right">Delivery*/}
                                                                    {/*        Fees*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder text-right pr-0">$15.00</td>*/}
                                                                    {/*</tr>*/}
                                                                    {/*<tr>*/}
                                                                    {/*    <td colSpan="2" className="border-0 pt-0"></td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">Grand*/}
                                                                    {/*        Total*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-success text-right pr-0">$1553.00</td>*/}
                                                                    {/*</tr>*/}
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                        {/*<div className="separator separator-dashed my-5"></div>*/}
                                                        {/*<h6 className="font-weight-bolder mb-3">Delivery Service Type:</h6>*/}
                                                        {/*<div className="text-dark-50 line-height-lg">*/}
                                                        {/*    <div>Overnight Delivery with Regular Packaging</div>*/}
                                                        {/*    <div>Preferred Morning (8:00AM - 11:00AM) Delivery</div>*/}
                                                        {/*</div>*/}
                                                        <div className="row justify-content-between">
                                                            <div className="d-flex flex-wrap">
                                                                <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                                                    data-wizard-type="action-submit"
                                                                    onClick={() =>{
                                                                        handleSubmitSingleOrder(order, "Submitted");
                                                                    }}>Submit
                                                                </button>
                                                            </div>
                                                            {
                                                                user.tenant == process.env.REACT_APP_WAHMEY_TENANT ?
                                                                    null
                                                                :
                                                                    <div className="d-flex">
                                                                        <button type="button" className="btn btn-info font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                                                            data-wizard-type="action-submit"
                                                                            onClick={() =>{
                                                                                handleSubmitSingleBackOrder(order);
                                                                            }}>Submit as Back Order
                                                                        </button>
                                                                        <button type="button" className="btn btn-warning font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                                                            data-wizard-type="action-submit"
                                                                            onClick={() =>{
                                                                                handleSubmitSingleQuote(order);
                                                                            }}>Submit as Quote
                                                                        </button>
                                                                    </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>

                                                    {/*<div className="border-bottom w-100"></div>*/}
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                        
                            <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                                {/* <div className="mr-2">
                                    <button type="button" className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                            data-wizard-type="action-prev">Previous
                                    </button>
                                </div> */}
                                <div>
                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 mr-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSaveOrders();
                                            }}>Update Cart
                                    </button>

                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSubmitSelectedOrders();
                                            }}>Submit Selected Orders
                                    </button>
                                    
                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSubmitOrder();
                                            }}>Submit ALL
                                    </button>
                                    {/* <Stripe
                                        stripeKey={ process.env.REACT_APP_STRIPE_PUBLIC }
                                        token={tokenHandler}
                                    >
                                        <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            >Pay & Submit
                                        </button>
                                    </Stripe> */}
                                </div>
                            </div>
                    </div>
                    
                :
                    isCartEmpty ?
                        <div className="card card-custom gutter-b">
                            <div className="card-body p-0">
                                <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                    <div className="col-md-10">
                                        <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                            <div className="text-center mb-4">
                                                <span className="card-label font-weight-bolder text-dark text-center font-size-lg">Shopping Cart</span>
                                            </div>
                                            <div className="text-center">
                                                <span className="text-dark-75 mt-3 font-weight-bold font-size-lg">Shopping Cart is empty!</span>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        null

            }
            
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                    { invoice?.shipVia == "HOME" ?
                            <Button onClick={() => handlePrintInvoice()} color="primary" autoFocus>
                                Print Invoice
                            </Button>
                            :
                            null
                    }
                    {
                        user.isBuyerAdmin && currOrder ? 
                            <Button onClick={() => handleSubmitSingleOrder(currOrder, "Submitted", true)} color="primary" autoFocus>
                                Force Submit
                            </Button>
                            :
                            null
                    }
                    {
                        user.isBuyerAdmin && invalidSelectedOrders.length < 1 && !isOrdersValid && isSelectedSubmit ? 
                            <Button onClick={() => handleSubmitSelectedOrders(true)} color="primary" autoFocus>
                                Force Submit
                            </Button>
                        :
                            null
                    }
                    {
                        user.isBuyerAdmin && invalidSelectedOrders.length < 1 && !isOrdersValid && isAllSubmit ? 
                            <Button onClick={() => handleSubmitOrder(true)} color="primary" autoFocus>
                                Force Submit All
                            </Button>
                        :
                            null
                    }

                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openBO}
                    onClose={handleCloseBO}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {messageBO}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseBO} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmitSingleOrder(currOrder, currOrderStatus, true)} color="primary" autoFocus>
                        Submit
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openCW}
                    onClose={handleCloseCW}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Catch Weight Input"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="d-flex flex-column text-md-left">
                                <span><span className="mb-1 font-weight-bolder">Product: </span> {catchWeightProduct?.name}</span>
                                <span><span className="mb-1 font-weight-bolder">Sku: </span> {catchWeightProduct?.sku}</span>
                                <span><span className="mb-1 font-weight-bolder">Left to Add: </span> {(catchWeightProduct?.amount - (catchWeightProduct?.catchWeights?.length ? catchWeightProduct?.catchWeights?.length : 0))}</span>
                            </div>
                        </DialogContentText>   

                        Weight: { (catchWeightProduct?.catchWeights.length > 0 ? catchWeightProduct?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0).toFixed(2)}
                        <div className="row justify-content-between py-8 px-20 py-md-10 px-md-0">
                            <div className="d-flex">
                                <input
                                    value={catchWeight}
                                    onChange={(e) => setCatchWeight(Number(e.target.value))}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleAddCatchWeight()
                                        }
                                    }}
                                    className={`form-control form-control-solid h-auto py-2 px-6`}
                                    type="number"
                                    min="0"
                                    style={{minWidth:"125px"}}
                                />
                                <Button onClick={() => handleAddCatchWeight()} color="primary" autoFocus>
                                    Add
                                </Button>
                            </div>
                        </div>
                        
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className='border-bottom'>
                                        <th className="text-center font-weight-bold text-muted text-uppercase">Product</th>
                                        <th className="text-center font-weight-bold text-muted text-uppercase">Weight</th>
                                        <th className="text-center font-weight-bold text-muted text-uppercase">Remove </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {   catchWeightProduct && catchWeightProduct.catchWeights?
                                            catchWeightProduct.catchWeights.map(cw => (
                                                <tr className="font-weight-boldest border-bottom-0">
                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                        {catchWeightProduct?.name + " (" + catchWeightProduct?.sku + ")"}
                                                    </td>
                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                        {cw}
                                                    </td>
                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                        <InputAdornment position="end">
                                                            <IconButton  onClick={() => handleRemoveCatchWeight(cw)}
                                                            >
                                                            <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                            </span>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </td>
                                                </tr>
                                            ))
                                        :
                                        null
                                    }
                                </tbody>
                            </table>

                        </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseCW} color="primary" autoFocus>
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            {/*<div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">*/}
            {/*    <div className="col-md-10">*/}
            {/*        <div className="d-flex justify-content-between">*/}
            {/*            <button type="button" className="btn btn-light-primary font-weight-bold"*/}
            {/*                    onClick="window.print();">Download Order Details*/}
            {/*            </button>*/}
            {/*            <button type="button" className="btn btn-primary font-weight-bold"*/}
            {/*                    onClick="window.print();">Place Order*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>

    );
}

export default injectIntl(ConfirmationPage);

import React from "react";

export function LocationFormatter(cellContent, row){
    
    return (
        <>
            <span className="d-block text-center font-size-lg">
                {row?.location[0] ? row.location[0].locationName : null}
            </span>
        </>
    )
}
import React, {useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import { fetchTenantDashboard } from "../Reports/ReportsCrud"
import Iframe from "react-iframe";
import jwt from "jsonwebtoken";

function ClientDashboardPage(props) {
    
    const [dashboard, setDashboard] = useState(null)
    const [isDashboardFound, setIsDashboardFound] = useState(false);
    const [iFrameUrl, setiFrameUrl] = useState("")

    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user,
            accessToken: auth.authToken,
        }));

    useEffect(() => {

        fetchTenantDashboard(accessToken).then(response => {
            console.log("Response: ", response)
            const dashboard = response.data.data
            
            if(dashboard){
                const METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL
                const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY

                // let payload = {
                //     resource: { dashboard: 620 },
                //     params: {},
                //     exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
                // };

                console.log("Full Name: ", user)
                let params = {}
                if(dashboard.parameters){
                    if(dashboard.parameters.length > 0){
                        for(let i = 0; i < dashboard.parameters.length; i++){
                            if(dashboard.parameters[i] == 'seller'){
                                params.seller = user.name
                            }
                        }
                    }
                }

                let payload = {
                    resource: { dashboard: dashboard.dashboardId },
                    params: params,
                    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
                }

                const token = jwt.sign(payload, METABASE_SECRET_KEY);

                setiFrameUrl(METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true")
                setDashboard(dashboard)
                setIsDashboardFound(true)
            }
            
      
          }).catch(error => console.log(error))
    }, [  ])

    return (
        <>
            { isDashboardFound && dashboard ? 
        
                <Card>
                    <CardHeader title={dashboard.name}>
                        <CardHeaderToolbar>
                            
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <div className="col-12">
                            <Iframe
                                url={iFrameUrl}
                                width="100%"
                                height="800px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                            />
                        </div>
                    </CardBody>
                </Card>
                :
                null
            }
            
        </>
       
    );
}

export default injectIntl(ClientDashboardPage);

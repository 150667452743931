import React,  { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { getCommonApplicationByCompanyId, getSupplierById } from "../CommonApplication/CommonApplicationCrud"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "axios"

function Invoice({ invoice, user, accessToken, setIsInvoiceClicked }) {

    const [companyOwner, setCompanyOwner] = useState("")
    const [supplierTenantId, setSupplierTenantId] = useState("")
    const [isSupplierFetched, setIsSupplierFetched] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [stateTax, setStateTax] = useState(0);
    const [municipalTax, setMunicipalTax] = useState(0);

    useEffect(() => {
        getCommonApplicationByCompanyId(invoice.companyId, accessToken).then((response) => {
            setCompanyOwner(response.data[0].personalInformation.personalFullLegalName)
        }).catch(error => console.log(error))

        // Fetch supplier to get supplier tenant id
        getSupplierById(invoice.supplierId, accessToken).then((response) => {
            setSupplierTenantId(response.data.tenantId)
            setIsSupplierFetched(true)
        }).catch(error => console.log(error))

        console.log("Invoice: ", invoice)
        if(invoice.shipVia == "HOME"){
            const salesTaxCode = invoice.company[0].salesTaxCode
            let municipalTaxSum = 0
            let stateTaxSum = 0

            invoice.products.forEach(currProduct => {
                console.log("Sales Tax Code: ", salesTaxCode)
                if(currProduct.isTaxable){
                    if(salesTaxCode == "SALES PR"){
                        stateTaxSum += (currProduct.price * currProduct.amount) * 0.105
                        municipalTaxSum += (currProduct.price * currProduct.amount) * 0.01
                    } else if(salesTaxCode == "10.5C"){
                        stateTaxSum += (currProduct.price * currProduct.amount) * 0.105
                    }
                }
            })

            setStateTax(stateTaxSum)
            setMunicipalTax(municipalTaxSum)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAlertClose = () => {
        setAlertOpen(false);
        setAlertTitle("");
        setAlertText("");
    }

    const fireAlert = (title, text) => {
        setAlertTitle(title);
        setAlertText(text);
        setAlertOpen(true);
    }

    let logoUrl = toAbsoluteUrl("/media/logos/allec-new-logo-m.png")
    if(user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_BIOWARE_SUPPLIER_TENANT){
        logoUrl = toAbsoluteUrl("/media/logos/bioware-logo.png")
    }

    const handleBack = () => {
        setIsInvoiceClicked(false)
    }

    function toFixedCustom(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    const handlePrintInvoice = async () => {
        // console.log("Invoice: ", invoice)

        let totalAmount = 0
        const salesOrderProductsData = invoice.products.map(currItem => {
            let itemPrice = currItem.listPrice ? currItem.listPrice : currItem.price
            let itemTotalAmount = 0

            if(invoice.shipVia == "HOME" && currItem.isCatchWeight){
                const totalWeight = currItem.catchWeights?.length > 0 ? currItem?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
                itemTotalAmount = (itemPrice * totalWeight)
                totalAmount += itemPrice * totalWeight;
            } else{
                itemTotalAmount = (itemPrice * currItem.amount)
                totalAmount += itemPrice * currItem.amount
            }
            
            return {
                name: currItem.name,
                category: currItem.category,
                sku: currItem.sku,
                unitPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                units: currItem.amount,
                itemPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                totalAmount: itemTotalAmount.toFixed(2),
                isCatchWeight: currItem.isCatchWeight,
                catchWeights: currItem.catchWeights ? currItem.catchWeights : [],
            }
        })

        console.log("Total Amount: ", totalAmount)
        console.log("Total Amount: ", stateTax)
        console.log("Total Amount: ", municipalTax)
        console.log("Total cost tax: ", (totalAmount + stateTax + municipalTax))

        const salesOrderData = {
            storeName: invoice.companyName,
            submissionDate: invoice.submissionDate,
            requestedDeliveryDate: invoice.shipDate,
            routeName: invoice.routes[0].name,
            orderNumber: invoice.orderNumber,
            salesRep: invoice.user[0].fullName,
            products: salesOrderProductsData,
            clientNumber: invoice.company[0].sageCustomerId,
            paymentTerm: invoice.company[0].dueDays,
            supplierName: invoice.supplier[0].entityName,
            supplierDescription: invoice.supplier[0].description,
            supplierPhone1: invoice.supplier[0].phoneNumber,
            supplierPhone2: invoice.supplier[0].phoneNumber2,
            supplierFax: invoice.supplier[0].fax,
            supplierAddress: `${invoice.supplier[0].physicalAddress}, ${invoice.supplier[0].physicalCity}, Puerto Rico, ${invoice.supplier[0].physicalZipcode}`,
            supplierDisclaimer: invoice.supplier[0].disclaimer,
            totalAmount: totalAmount,
            sageInvoiceNumber: invoice.sageInvoiceNumber ? invoice.sageInvoiceNumber : false,
            salesTaxAmount: invoice.salesTaxAmount ? invoice.salesTaxAmount : false,
            totalCostTax: (totalAmount + stateTax + municipalTax),
            comment: invoice.comment,
            stateTax: stateTax,
            municipalTax: municipalTax
        }
        
        console.log("Sales Order Data: ", salesOrderData)

        const urlPath = process.env.REACT_APP_WMS_API_HTTPS + (process.env.REACT_APP_ENV === 'DEV' ?
            `${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/store_order_endpoint_pdf` :
            `${supplierTenantId}.${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/store_order_endpoint_pdf`
          );

        const response = await axios.post(urlPath, 
        // const response = await axios.post("http://localhost:8003/api/tenants/wahmey/route_records/store_order_endpoint_pdf", 
        salesOrderData, {
            responseType: 'arraybuffer', // Ensure the response type is set to arraybuffer
        });

        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new tab with the PDF
        const newTab = window.open(pdfUrl, '_blank');
    }

    const handlePrintFinalInvoice = async () => {
        // console.log("Invoice: ", invoice)
        const salesOrderData = {
            _id: invoice._id,
        }
        console.log("Sales Order Data: ", salesOrderData)

        const urlPath = process.env.REACT_APP_WMS_API_HTTPS + (process.env.REACT_APP_ENV === 'DEV' ?
            `${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/final_order_invoice_endpoint_pdf` :
            `${supplierTenantId}.${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/final_order_invoice_endpoint_pdf`
          );

        const response = await axios.post(urlPath, 
        // const response = await axios.post("http://localhost:8003/api/tenants/wahmey/route_records/final_order_invoice_endpoint_pdf", 
        salesOrderData, {
            responseType: 'arraybuffer', // Ensure the response type is set to arraybuffer
        });

        const contentType = response.headers['content-type'];
  
        if (contentType && contentType.includes('application/json')) {
            // Convert arraybuffer to JSON string, then parse it
            const jsonString = Buffer.from(response.data).toString('utf8');
            const jsonData = JSON.parse(jsonString);
            console.log("Parsed JSON:", jsonData);

            fireAlert("Dispatch Pending", jsonData.message)
        } else {
            // Create a Blob from the PDF data
            console.log("Response: ", response);
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

            // Create a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Open a new tab with the PDF
            const newTab = window.open(pdfUrl, '_blank');
        }

        
    }

    return (
        <>
            <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                    <div className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style={{backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-8.jpg")})`}} >
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 text-white font-weight-boldest mb-10">INVOICE</h1>
                                <div className="d-flex flex-column align-items-md-end px-0">
                                    {/* <a href="#" className="mb-5">
                                        <img src="assets/media/logos/logo-light.png" alt=""/>
                                    </a> */}
                                    <span id="invoiceDistribuyeLogo" className="text-white d-flex flex-column align-items-md-end">
                                        <img
                                            alt="Logo"
                                            className="max-h-80px"
                                            src={logoUrl}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between text-white pt-6">
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Invoice Date</span>
                                    <span className="">{invoice.invoiceDate}</span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Ship Date</span>
                                    <span className="">{invoice.shipDate}</span>
                                </div>
                                { invoice.sageInvoiceNumber ? 
                                        <div className="d-flex flex-column flex-root">
                                            <span className="font-weight-bolder mb-2">Invoice NO.</span>
                                            <span className="">{invoice.sageInvoiceNumber}</span>
                                        </div>
                                    :
                                        null
                                }
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Order NO.</span>
                                    <span className="">{invoice.orderNumber}</span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Payment Term</span>
                                    <span className="">{invoice.company[0].dueDays == 0 ? "COD" : `${invoice.company[0].dueDays} days`  }</span>
                                </div>
                                {/* <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Order TO.</span>
                                    <span className="">{invoice.supplierName}</span>
                                </div> */}
                            </div>
                            <div className="border-bottom w-100 opacity-20"></div>
                            <div className="d-flex justify-content-between text-white pt-6">
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Comments</span>
                                    <span className="">{invoice.comment}</span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Cutomer PO</span>
                                    <span className="">{invoice.customerPo}</span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">Ship via</span>
                                    <span className="">{invoice.shipVia}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-9">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="pl-0 font-weight-bold text-muted  text-uppercase">Product</th>
                                            <th className="text-right font-weight-bold text-muted text-uppercase">Sku</th>
                                            <th className="text-right font-weight-bold text-muted text-uppercase">Unit Price</th>
                                            <th className="text-right font-weight-bold text-muted text-uppercase">Item Price</th>
                                            <th className="text-right font-weight-bold text-muted text-uppercase">Product Qty.</th>
                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice.products.map(product => 
                                            <tr className="font-weight-boldest font-size-lg">
                                                <td className="border-top-0 pl-0 py-2 d-flex align-items-center">
                                                    <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                        <div className="symbol-label"
                                                            style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                                    </div>
                                                    {product.name}
                                                </td>
                                                <td className="border-top-0 text-right pt-7">{product.sku}</td>
                                                <td className="border-top-0 text-right pt-7">
                                                    {product.listPrice?
                                                        <span className="label label-lg label-light-primary label-inline">${product.listPrice}</span>
                                                        :
                                                        <span>${product.price}</span>
                                                    }
                                                </td>
                                                
                                                <td className="border-top-0 text-right pt-7">
                                                    {
                                                        product.estimatedPrice ? 
                                                            <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice), 2)}</span>
                                                        :
                                                            product.listPrice?
                                                                <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice), 2)}</span>
                                                            :
                                                                <span>${toFixedCustom((product.price), 2)}</span>
                                                    }
                                                </td>
                                                <td className="border-top-0 text-right pt-7">{product.amount}</td>
                                                <td className="text-danger border-top-0 pr-0 pt-7 text-right">
                                                    {
                                                        product.estimatedPrice ? 
                                                            <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice * product.amount), 2)}</span>
                                                        :
                                                            product.listPrice?
                                                                <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice * product.amount), 2)}</span>
                                                            :
                                                                <span>${toFixedCustom((product.price * product.amount), 2)}</span>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                                <div className="d-flex flex-column mb-10 mb-md-0">
                                    {/* <div className="font-weight-bolder font-size-lg mb-3">BANK TRANSFER</div> */}

                                    <div className="d-flex justify-content-between mb-1">
                                        <span className="mr-15 font-weight-bold">{companyOwner}</span>
                                    </div>

                                    <div className="d-flex justify-content-between mb-1">
                                        <span className="mr-15 font-weight-bold">{invoice.companyName}</span>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <span className="mr-15 font-weight-bold">{invoice.locationAddressCity}</span>
                                    </div>
                                </div>
                                { invoice.totalCostTax ? 
                                    <div className="d-flex flex-column text-md-right">
                                        <span className="mb-1">Sub Total: {`$${invoice.totalCost}`}</span>
                                        <span className="mb-1">Sales Tax Amount: {`$${invoice.salesTaxAmount}`}</span>
                                        <span className="font-size-lg font-weight-bolder mb-1">TOTAL AMOUNT</span>
                                        <span className="font-size-h2 font-weight-boldest text-danger mb-1">
                                                {`$${invoice.totalCostTax}`}
                                        </span>
                                        <span>Taxes Included</span>
                                    </div>
                                :
                                    <div className="d-flex flex-column text-md-right">
                                        { invoice.shipVia == "HOME" ? 
                                            <>
                                                <span><span className="mb-1 font-weight-bolder">SUB TOTAL: </span> {`$${toFixedCustom(invoice.totalCost, 2)}`}</span>
                                                <span><span className="mb-1 font-weight-bolder">STATE TAX 10.5%: </span> {`$${toFixedCustom(stateTax, 2)}`}</span>
                                                <span><span className="mb-1 font-weight-bolder">MUNICIPAL TAX 1%: </span> {`$${toFixedCustom(municipalTax, 2)}`}</span>
                                                <span><span className="mb-1 font-weight-bolder">TOTAL TAX: </span> {`$${toFixedCustom(stateTax + municipalTax, 2)}`}</span>
                                                <span><span className="mb-1 font-weight-bolder">TOTAL: </span> {`$${toFixedCustom(invoice.totalCost + stateTax + municipalTax, 2)}`}</span>
                                            </>
                                            :
                                            <>
                                                <span className="font-size-lg font-weight-bolder mb-1">TOTAL AMOUNT</span>
                                                <span className="font-size-h2 font-weight-boldest text-danger mb-1">
                                                        {`$${toFixedCustom(invoice.totalCost, 2)}`}
                                                </span>
                                                <span>Taxes NOT Included</span>
                                            </>
                                        }
                                        
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="row justify-content-between py-8 px-8 py-md-10 px-md-0">
                        <div className="d-flex flex-wrap">
                            <button id="downloadInvoiceButton" type="button" className="btn btn-primary font-weight-bold ml-10" onClick={() => {
                                    handleBack()
                                }}>Back
                            </button>
                        </div>
                        {
                            user.tenant == process.env.REACT_APP_WAHMEY_TENANT ?
                                <div className="d-flex">
                                    <button id="printInvoiceButton" disabled={!isSupplierFetched} type="button" className="btn btn-light-primary font-weight-bold " onClick={() => {
                                            handlePrintInvoice()
                                        }}>Print Invoice
                                    </button>

                                    <button id="printFinalInvoiceButton" disabled={!isSupplierFetched} type="button" className="btn btn-light-primary font-weight-bold ml-5 mr-10" onClick={() => {
                                            handlePrintFinalInvoice()
                                        }}>Print Final Invoice
                                    </button>
                                </div>
                            :
                                <div className="d-flex">
                                    <button id="printInvoiceButton" disabled={!isSupplierFetched} type="button" className="btn btn-light-primary font-weight-bold mr-10" onClick={() => {
                                            handlePrintInvoice()
                                        }}>Print Invoice
                                    </button>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div>
                <Dialog
                    open={alertOpen}
                    onClose={handleAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAlertClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
    
export default injectIntl(Invoice);
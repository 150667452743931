import axios from "axios";

export function fetchTenantDashboard(authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.get(process.env.REACT_APP_API_URL + '/tenants/dashboard', options)
}

export function fetchTenantReports(authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/tenants/reports', options)
}

export function fetchTenantReport(authToken, path) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: {
            path: path
        }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/tenants/report', options)
}
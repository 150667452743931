import React, {useEffect, useState, useRef} from "react";
// import { connect } from "react-redux";
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {getOrdersByUser} from "../../../../redux/actions/orders";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";
import { getOrderInfo, getOrdersTenantPagination, fetchTenantUsers } from "./ordersCrud";
import OrderSummary from "./OrderSummary";
import SuppliersPage from "./SuppliersPage";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import {ordersCountActions} from '../../../../redux/reducers/ordersCount';
import Input from '@material-ui/core/Input';

function OrdersPage(props) {

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [newOrder, setNewOrder] = useState(false);
    const [data, setData] = useState(initialData)
    const [isOrderSummaryClicked, setIsOderSummaryClicked] = useState(false)
    const [isOrderUpdated, setIsOrderUpdated] = useState(false)
    const [isMounted, setIsMounted] = useState(false)
    const [orderDetails, setOrderDetails] = useState({})
    const [tenantUsers, setTenantUsers] = useState([])
    const [userFilter,setUserFilter] = useState(null)
    const [statusFilter, setStatusFilter] = useState(props.status)

    const [fromSubmissionDate, setFromSubmissionDate] = useState("")
    const [toSubmissionDate, setToSubmissionDate] = useState("")
    const [fromInvoiceDate, setFromInvoiceDate] = useState("")
    const [toInvoiceDate, setToInvoiceDate] = useState("")

    const initialRender = useRef(true);

    const orderStatus = props.status ? props.status : ''

    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const dispatch = useDispatch();

    useEffect(() => {
        // if(typeof (props.isNewOrder) !== 'undefined' && props.isNewOrder !== ''){
        //     setNewOrder(props.isNewOrder)
        // }
        // if (typeof (props.pathId) !== 'undefined' && props.pathId !== '') {
        //     fetchOrder(props.pathId)
        // }

        dispatch(ordersCountActions.getOrdersCount(accessToken, user.id))

        fetchTenantUsers(accessToken).then(response => {
            setTenantUsers(response.data)

            setIsMounted(true)
        }).catch(error => console.log(error))

    }, [])

    const fetchOrders = async (queryParams, createdBy) => {
        
        getOrdersTenantPagination(accessToken, queryParams).then(response => {
            console.log("Response: ", response.data.data)
            const data = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : 10
            }
            setData(data)
        }).catch(error => console.log(error))
    }

    let initialFilter = {
        filter: {
            status: orderStatus,
            searchText: "",
            fromInvoiceDate: "",
            toInvoiceDate: ""
        },
        sortOrder: "desc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 10,
        status: orderStatus,
        createdBy: userFilter ? userFilter : "",
    }

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            fetchOrders(initialFilter)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.status])

    let companyColumn = {
        dataField: "companyName",
        text: "Company",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                setOrderDetails(row)
                setIsOderSummaryClicked(true)
            }
        },
    }
    
    let columns = []

    // TODO: Replace env vars for new sellers role

    if(user.isSeller && user.isBuyerAdmin){
        companyColumn.text = "Customer"
        columns = [
            {
                dataField: "orderNumber",
                text: "Order Number",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
                formatter: columnFormatters.OrderNumberFormatter,
            },
            {
                dataField: "salesRep",
                text: "Sales Rep",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
                formatter: columnFormatters.SalesRepFormatter,
            },
            {
                dataField: "submissionDate",
                text: "Submission Date",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "invoiceDate",
                text: "Invoice Date",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            companyColumn,
            {
                dataField: "location",
                text: "Location",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.LocationFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "routeName",
                text: "Route",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.RouteFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "totalCost",
                text: "Order Total",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.TotalCostFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "totalProductQty",
                text: "Products Quantity",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.TotalProductFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.StatusFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
        ];
        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            columns.push({
                dataField: "routeStatus",
                text: "Route Status",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.RouteStatusFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },)
        }

    } else if(user.isSeller){
        companyColumn.text = "Customer"
        columns = [
            {
                dataField: "orderNumber",
                text: "Order Number",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
                formatter: columnFormatters.OrderNumberFormatter,
            },
            {
                dataField: "submissionDate",
                text: "Submission Date",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "invoiceDate",
                text: "Invoice Date",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            companyColumn,
            {
                dataField: "location",
                text: "Location",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.LocationFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "routeName",
                text: "Route",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.RouteFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "totalCost",
                text: "Order Total",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.TotalCostFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "totalProductQty",
                text: "Products Quantity",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.TotalProductFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.StatusFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
        ];
        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            columns.push({
                dataField: "routeStatus",
                text: "Route Status",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.RouteStatusFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },)
        }
    } else{
        columns = [
            {
                dataField: "orderNumber",
                text: "Order Number",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
                formatter: columnFormatters.OrderNumberFormatter,
            },
            {
                dataField: "submissionDate",
                text: "Submission Date",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "invoiceDate",
                text: "Invoice Date",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            companyColumn,
            {
                dataField: "location",
                text: "Location",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.LocationFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "routeName",
                text: "Route",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "supplierName",
                text: "Supplier",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "totalCost",
                text: "Order Total",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.TotalCostFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "totalProductQty",
                text: "Products Quantity",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.TotalProductFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses,
                formatter: columnFormatters.StatusFormatter,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        setOrderDetails(row)
                        setIsOderSummaryClicked(true)
                    }
                },
            },
        ];
    }

    

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    const sorting = [{dataField: "orderNumber", order: "desc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText, fromDate, toDate} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.status = orderStatus ? orderStatus : "";
        filter.searchText = searchText ?? "";
        filter.fromInvoiceDate = fromDate ?? "";
        filter.toInvoiceDate = toDate ?? "";

        newQueryParams.filter = filter;
        newQueryParams.status = orderStatus ? orderStatus : ""
        newQueryParams.createdBy = userFilter
        return newQueryParams;
    }
    
    const handleUserFilter = (element) => {

        const clickedTenantUser = tenantUsers.find(tenantUser => tenantUser._id == element.target.value)

        // console.log("Clicked user: ", clickedTenantUser)

        if(clickedTenantUser) {
            setUserFilter(clickedTenantUser._id)

            const queryParams = {
                ...initialFilter,
                createdBy: clickedTenantUser._id
            }
            fetchOrders(queryParams)

        } else{
            setUserFilter("")
            const queryParams = {
                ...initialFilter,
                createdBy: ""
            }
            fetchOrders(queryParams)
        }
    }

    // console.log("Re render!!", user)

    return (
        <>
            {isMounted ? !newOrder ? isOrderSummaryClicked ?
                <OrderSummary currOrder={orderDetails} setIsOderSummaryClicked={setIsOderSummaryClicked} 
                    setIsOrderUpdated={setIsOrderUpdated} isOrderUpdated={isOrderUpdated}/>
                :
                <Card>
                    <CardHeader title="All Orders">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                setNewOrder(true)
                            }}>
                                Create New Order
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        {
                            user.isBuyerAdmin ? 
                                <div className="row justify-content-between">
                                    <div className="d-flex flex-wrap col-3">
                                        <FormControl variant="filled" style={{ width: "100" }}>
                                            <InputLabel>User Filter</InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                value={userFilter ? userFilter : ""}
                                                style={{ minWidth: "200px" }}
                                                input={<FilledInput name="userFilter" id="userFilter"
                                                    onChange={(element) => {
                                                        handleUserFilter(element);
                                                        // setCompany(companies.find(company => company._id == element.target.value))
                                                        // setLocations(companies.find(company => company._id == element.target.value).locations)
                                                    } }
                                                    type="text"
                                                    className="form-control form-control-solid h-auto mb-5" />}>

                                                <MenuItem key={0} value={""}>All</MenuItem>
                                                {tenantUsers.map((tenantUser,index) =>(
                                                    <MenuItem key={tenantUser._id} value={tenantUser._id}>{tenantUser.fullName}</MenuItem>
                                                ))

                                                }

                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-1">

                                    </div>
                                    <div className="d-flex col-8">
                                        {/* <div className="row mr-8">
                                            <div className="form-group fv-plugins-icon-container mr-5">
                                                <label style={{ display: "block" }} htmlFor={"invoiceDate"}>From Invoice Date</label>
                                                <Input
                                                    type="date"
                                                    className={`h-auto py-1 px-6`}
                                                    style={{ display: "block" }}
                                                    name="fromInvoiceDate"
                                                    value={fromInvoiceDate}
                                                    onChange={(e) => setFromInvoiceDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group fv-plugins-icon-container">
                                                <label style={{ display: "block" }} htmlFor={"invoiceDate"}>To Invoice Date</label>
                                                <Input
                                                    type="date"
                                                    style={{ display: "block" }}
                                                    className={`h-auto py-1 px-6`}
                                                    name="fromInvoiceDate"
                                                    value={toInvoiceDate}
                                                    onChange={(e) => setToInvoiceDate(e.target.value)}
                                                />
                                            </div>
                                        </div> */}
                                        {/* <div className="row">
                                            <div className="form-group fv-plugins-icon-container mr-5">
                                                <label style={{ display: "block" }} htmlFor={"invoiceDate"}>From Submission Date</label>
                                                <Input
                                                    type="date"
                                                    style={{ display: "block" }}
                                                    className={`h-auto py-1 px-6`}
                                                    name="fromInvoiceDate"
                                                    value={fromSubmissionDate}
                                                    onChange={(e) => setFromSubmissionDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group fv-plugins-icon-container">
                                                <label style={{ display: "block" }} htmlFor={"invoiceDate"}>To Submission Date</label>
                                                <Input
                                                    type="date"
                                                    style={{ display: "block" }}
                                                    className={`h-auto py-1 px-6`}
                                                    name="fromInvoiceDate"
                                                    value={toSubmissionDate}
                                                    onChange={(e) => setToSubmissionDate(e.target.value)}
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                
                                :
                                null
                        }
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            initialFilter={initialFilter}
                            tableClasses={tableClasses}
                            fetcher={fetchOrders}
                            filterFunction={prepareFilter}
                            searchField={true}
                            dateRangeField={true}
                            dateRangeName={"Invoice"}/>
                    </CardBody>
                </Card>
                :
                <SuppliersPage setNewOrder={setNewOrder} />
                :
                null
            }
        </>
    );
}

export default injectIntl(OrdersPage);
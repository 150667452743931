import React, {useEffect, useState, useMemo} from "react";
import {useHistory} from "react-router-dom";
import SVG from "react-inlinesvg";
import {headerSortingClasses, sortCaret, toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable"
import Pagination from "react-js-pagination"
import {injectIntl} from "react-intl";
import {Card} from "react-bootstrap";
import {getFavorites, fetchCompanies, postProductsCart, fetchFavoriteListProducts, favoriteOrderSubmit, favoriteOrderSave} from "./crud/ordersFavoritesCrud"
import { fetchTenantUsers } from '../Orders/ordersCrud';
import CategoryCollapseItemList from './CategoryCollapseItemList'
import Input from '@material-ui/core/Input';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    FilledInput,
    FormControl,
    InputLabel, makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ListSubheader,
    TextField,
    InputAdornment,
    Collapse
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import OrdersFavoritesNumberField from "./OrderFavoritesNumberField";
import {cartCountActions} from "../../../../redux/reducers/cartCount";
import {useDispatch, useSelector} from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import {Formik} from "formik";
import axios from 'axios';

const sizePerPageList = [
    {text: "3", value: 3},
    {text: "5", value: 5},
    {text: "10", value: 10}
];

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        color: theme.palette.text.secondary,
    },
    headingSpinner: {
        display: "flex",
        flexBasis: '33.33%',
        'justify-content': "flex-end",
        'align-items': "center",
        'margin-right': '20px',
    }
}));

function OrderFavoritesTable(props){

    const classes = useStyles();

    const {userId, isBuyerAdmin, activeTenant, accessToken, isSeller} = useSelector(
        ({auth}) => ({
            userId: auth.user.id,
            isBuyerAdmin: auth.user.isBuyerAdmin,
            activeTenant: auth.user.tenant,
            accessToken: auth.authToken,
            isSeller: auth.isSeller,
        })
    )

    const initialPaginationOptions = {
        totalSize: 0,
        sizePerPage: 10,
        page: 1
    }

    const history = useHistory();
    const dispatch = useDispatch();

    const [favoritesList, setFavoritesList] = useState([]);
    const [submitList, setSubmitList] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [locations, setLocations] = useState([]);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [listRecords, setListRecords] = useState([]);
    const [casesQuantity, setCasesQuantity] = useState(0);
    const [totalOrderPrice, setTotalOrderPrice] = useState(0);

    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [isTableReady, setIsTableReady] = useState(false);
    const [isPanelChangeDisabled, setIsPanelChangeDisabled] = useState(false);
    const [isSubmitExpanded, setIsSubmitExpanded] = useState(false);
    const [alertText, setAlertText] = useState("");

    const [submitListIndex, setSubmitListIndex] = useState(-1);
    

    const [categoryProductsObjectList, setCategoryProductsObjectList] = useState([]);
    
    const [selectedListId, setSelectedListId] = useState("");
    const [comment, setComment] = useState("");
    const [customerPo, setCustomerPo] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [defaultDate, setDefaultDate] = useState("");
    const [shipDate, setShipDate] = useState("");
    const [shipVia, setShipVia] = useState("");

    const [invoice,setInvoice] = useState(null);

    const [isSubmitValid, setIsSubmitValid] = useState(false);

    const [expanded, setExpanded] = useState("");

    const [tenantUsers, setTenantUsers] = useState([])
    const [userFilter,setUserFilter] = useState("")

    const [searchText, setSearchText] = useState("");
    const [itemSearchText, setItemSearchText] = useState("");
    const [searchName,setSearchName] = useState("")
    const [paginationOptions,setPaginationOptions] = useState(initialPaginationOptions)

    const [openCW, setOpenCW] = useState(false);
    const [catchWeight, setCatchWeight] = useState("")
    const [catchWeightProduct, setCatchWeightProduct] = useState(null)

    const containsText = (text, searchText) => {
        // console.log(companies)
        if(text){
            return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }
        
    }

    const displayedOptions = useMemo(
        () => companies.filter((company) => containsText(company.businessName, searchText) || containsText(company.sageCustomerId, searchText)),
        [searchText, companies]
    );

    const handleOrderClicked = (order) => {
        history.push(    `/orders-messaging/${order._id}`)
    };


    const fetchFavorites = async (flSearchName = "", pageNumber, pageSize, createdById) => {
        const response = await getFavorites(accessToken, flSearchName, pageNumber, pageSize, createdById);

        if (response.status != 200){
            //TODO: Alert
            console.log("ERRORRRR!", response.status)
            return;
        }

        console.log("Data: ", response)
        const data = response.data.data;

        for(const dataIndex in data){
            data[dataIndex]['company'] = {};
            data[dataIndex]['location'] = {};
        }

        setPaginationOptions({
            page: pageNumber,
            totalSize: response.data.totalCount,
            sizePerPage: pageSize
        })

        console.log("Data: ", data)

        setFavoritesList(data);
        setIsTableReady(true)
    }

    useEffect(() => {
        if(activeTenant == process.env.REACT_APP_BIOWARE_TENANT){
            fetchFavorites("", paginationOptions.page, paginationOptions.sizePerPage, userId).then(r => {
            
            });
            fetchTenantUsers(accessToken).then(response => {
                setTenantUsers(response.data)
                setUserFilter(userId)
            }).catch(error => console.log(error))
        } else{
            fetchFavorites("", paginationOptions.page, paginationOptions.sizePerPage, "").then(r => {
            
            });
            fetchTenantUsers(accessToken).then(response => {
                setTenantUsers(response.data)
            }).catch(error => console.log(error))
        }
    }, [])


    const newFavoriteList = () => {
        history.push("/orders-favorites/new")
    }

    const handleChangeQuantity = (listIndex, categoryId, productId, productIndex, quantity) => {
        let tempFavoritesList = [...favoritesList];

        let newQuantity = parseInt(quantity)

        if(isNaN(newQuantity)){
            newQuantity = ""
        }

        const productIndexFound = tempFavoritesList[listIndex].products.findIndex(currProd => currProd._id == productId)
        let newCategoryProductsObject = [...categoryProductsObjectList]

        if(productIndexFound >= 0){
            let currProduct = {...tempFavoritesList[listIndex].products[productIndexFound]}
            const oldQuantity = typeof currProduct.quantity === 'number' && !isNaN(currProduct.quantity) ? currProduct.quantity : 0
            let itemPrice = currProduct.listPrice ? currProduct.listPrice : currProduct.price

            let newTotalOrderPrice = totalOrderPrice

            // Remove old cost from total
            if(currProduct.isCatchWeight){
                if(currProduct.isStaticCatchWeight && shipVia == "HOME"){
                    newTotalOrderPrice -= parseFloat(itemPrice) * currProduct.staticCatchWeight * oldQuantity
                }
            } else{
                newTotalOrderPrice -= (oldQuantity * itemPrice)
            }

            // Add new cost to total
            if(newQuantity > 0 && newQuantity != ''){
                if(currProduct.isCatchWeight){
                    if(currProduct.isStaticCatchWeight && shipVia == "HOME"){
                        newTotalOrderPrice += parseFloat(itemPrice) * currProduct.staticCatchWeight * newQuantity 
                    }
                } else{
                    newTotalOrderPrice += (newQuantity * itemPrice)
                }
            }
            
            currProduct['quantity'] = newQuantity;
            currProduct['amount'] = newQuantity;

            const CategoryProductObjectIndexFound = newCategoryProductsObject.findIndex(currObject => currObject.category._id == categoryId)

            if(CategoryProductObjectIndexFound >= 0){

                const catObjProduct = newCategoryProductsObject[CategoryProductObjectIndexFound].products[productIndex]
                if(shipVia == "HOME"){
                    if(currProduct.isStaticCatchWeight){
                        let catchWeights = []
                        for(let i = 0;i < parseInt(newQuantity); i++){
                            catchWeights.push(currProduct.staticCatchWeight)
                        }
                        currProduct.catchWeights = catchWeights
                    } else if (currProduct.isCatchWeight){
                        
                        currProduct.catchWeights = Array.isArray(catObjProduct?.catchWeights) ? catObjProduct?.catchWeights : []
                    }
                }

                newCategoryProductsObject[CategoryProductObjectIndexFound].products[productIndex]['quantity'] = newQuantity
                if(currProduct.isCatchWeight && shipVia == "HOME"){
                    newCategoryProductsObject[CategoryProductObjectIndexFound].products[productIndex].catchWeights = currProduct.catchWeights
                }

                const newCasesQuantity = casesQuantity - oldQuantity + newQuantity

                const selectedProductFoundIndex = selectedProducts.findIndex(currProduct => currProduct._id == productId)
                if(selectedProductFoundIndex >= 0){
                    
                    if(newQuantity <= 0 || newQuantity == ''){
                        if(currProduct.isCatchWeight && shipVia == "HOME"){
                            newTotalOrderPrice -= parseFloat(itemPrice) * (currProduct.catchWeights.length > 0 ? currProduct?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0)
                            newCategoryProductsObject[CategoryProductObjectIndexFound].products[productIndex].catchWeights = []
                        }
                        setSelectedProducts(oldSelectedProducts => oldSelectedProducts.filter(curr => curr._id != productId))
                    } else{
                        let updatedSelectedProducts = [...selectedProducts]
                        updatedSelectedProducts[selectedProductFoundIndex].quantity = newQuantity
                        updatedSelectedProducts[selectedProductFoundIndex].amount = newQuantity
                        if(updatedSelectedProducts[selectedProductFoundIndex].isCatchWeight && shipVia == "HOME"){
                            updatedSelectedProducts[selectedProductFoundIndex].catchWeights = currProduct.catchWeights
                        }
                        
                        setSelectedProducts(updatedSelectedProducts)
                    }
                } else{
                    if(newQuantity > 0){
                        setSelectedProducts(oldSelectedProducts => [ ...oldSelectedProducts, { ...currProduct } ])
                        
                    }
                }

                tempFavoritesList[listIndex].products[productIndexFound] = currProduct

                setTotalOrderPrice(parseFloat(newTotalOrderPrice))
                setCasesQuantity(newCasesQuantity)
                setCategoryProductsObjectList(newCategoryProductsObject)
                setFavoritesList([...tempFavoritesList]);
            } else{
                console.log("If we got here something went wrong.")
            }
        } else {
            console.log("This should not be possible! How did we get here? ")
        }
    }

    const handlePriceChange = (listIndex, categoryId, productId, productIndex, newPrice) => {

        let newList = {...favoritesList[listIndex]}
        let newProducts = [...newList.products]
        let newTotalOrderPrice = totalOrderPrice

        const productIndexFound = newProducts.findIndex(currProd => currProd._id == productId)
        let newCategoryProductsObject = [...categoryProductsObjectList]

        if(productIndexFound >= 0){
            const CategoryProductObjectIndexFound = newCategoryProductsObject.findIndex(currObject => currObject.category._id == categoryId)

            if(CategoryProductObjectIndexFound >= 0){
                const product = newProducts[productIndexFound]

                let newPriceParsed = parseFloat(newPrice)

                if(isNaN(newPriceParsed)){
                    newPriceParsed = ""
                }

                if(product.listPrice || product.listPrice == ''){
                    newProducts[productIndexFound] = {
                        ...product,
                        listPrice : newPriceParsed,
                    }
                    newCategoryProductsObject[CategoryProductObjectIndexFound].products[productIndex]['listPrice'] = newPriceParsed
                } else{
                    newProducts[productIndexFound] = {
                        ...product,
                        price : newPriceParsed,
                    }
                    newCategoryProductsObject[CategoryProductObjectIndexFound].products[productIndex]['price'] = newPriceParsed
                }

                const selectedProductFoundIndex = selectedProducts.findIndex(currProduct => currProduct._id == productId)
                if(selectedProductFoundIndex >= 0){
                    const foundSelectedProduct = selectedProducts[selectedProductFoundIndex]
                    const oldPrice = (product.listPrice && product.listPrice != '') ? product.listPrice : (product.price && product.price != '') ? product.price : 0

                    if(foundSelectedProduct.isCatchWeight){
                        if(Array.isArray(foundSelectedProduct?.catchWeights)){
                            const totalWeight = foundSelectedProduct?.catchWeights.length > 0 ? foundSelectedProduct?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
                            newTotalOrderPrice = newTotalOrderPrice - (totalWeight * oldPrice)
                        }
                    } else{
                        newTotalOrderPrice = newTotalOrderPrice - (foundSelectedProduct.quantity * oldPrice)
                    }
    
                    if(newPriceParsed > 0 && newPriceParsed != ''){
                        if(foundSelectedProduct.isCatchWeight){
                            if(Array.isArray(foundSelectedProduct?.catchWeights)){
                                const totalWeight = foundSelectedProduct?.catchWeights.length > 0 ? foundSelectedProduct?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
                                console.log("Total weight: ", totalWeight)
                                console.log("New Price", newPriceParsed)
                                newTotalOrderPrice += (totalWeight * newPriceParsed)
                            }
                        } else{
                            newTotalOrderPrice += (product.quantity * newPriceParsed)
                        }
                    }
                    
                    

                    if(foundSelectedProduct.listPrice || foundSelectedProduct.listPrice == ''){
                        let updatedSelectedProducts = [...selectedProducts]
                        updatedSelectedProducts[selectedProductFoundIndex].listPrice = newPriceParsed
                        setSelectedProducts(updatedSelectedProducts)
                    } else{
                        let updatedSelectedProducts = [...selectedProducts]
                        updatedSelectedProducts[selectedProductFoundIndex].price = newPriceParsed
                        setSelectedProducts(updatedSelectedProducts)
                    }
                }

                newList.products = newProducts
                let newFavoriteList = [...favoritesList]
                newFavoriteList[listIndex] = newList
                
                setTotalOrderPrice(parseFloat(newTotalOrderPrice))
                setCategoryProductsObjectList(newCategoryProductsObject)
                setFavoritesList(newFavoriteList);
            } else{
                console.log("If we got here something went wrong.")
            }
            
        } else {
            console.log("This should not be possible! How did we get gere? ")
        }
        
    }

    const addToCart = async (favoriteListIndex) => {

        if(favoritesList[favoriteListIndex].company == null){
            fireAlert("Validation Error", "The company field is required");
            return;
        }else if(favoritesList[favoriteListIndex].location._id == null){
            fireAlert("Validation Error", "The location field is required");
            return;
        }else if (selectedProducts.filter(p => p.quantity > 0).length <= 0){
            fireAlert("Validation Error", "Please add the quantity for at least one product");
            return;
        }

        const data = {
            products: selectedProducts,
            location: favoritesList[favoriteListIndex].location,
            supplierId: favoritesList[favoriteListIndex].supplierId,
            companyId: favoritesList[favoriteListIndex].company._id,
            companyName: favoritesList[favoriteListIndex].company.businessName,
            comment: comment,
            customerPo: customerPo,
            shipVia: shipVia,
            invoiceDate: invoiceDate,
            shipDate: shipDate
        }

        await favoriteOrderSave(accessToken, data).then(response =>{
            dispatch(cartCountActions.getProductCount(accessToken, userId));
            if(listRecords.length > 1){
                const listRecordIndexFound = listRecords.findIndex(curr => curr.favoriteListId === favoritesList[favoriteListIndex]._id)
                
                let newListRecords = [...listRecords]
                if(listRecordIndexFound >= 0){
                    
                    newListRecords.splice(listRecordIndexFound, 1)
                
                    setCasesQuantity(0)
                    setTotalOrderPrice(0)
                    setSelectedProducts([])
                    setInvoiceDate("")
                    setShipDate("")
                    setComment("")
                    setShipVia("")
                    setCustomerPo("")
                    setCategoryProductsObjectList([])
                    setListRecords(newListRecords)
                }
                let tempFavorites = [...favoritesList];
                tempFavorites[favoriteListIndex].company = {};
                tempFavorites[favoriteListIndex].locations = [];
                tempFavorites[favoriteListIndex].location = {};
                
                setFavoritesList(tempFavorites)
                setSelectedListId("")
                setExpanded("")

                if(newListRecords.length > 0){
                    fireAlert("Success", favoritesList[favoriteListIndex].name + " has been added to cart. Other Lists still in progress.")
                } else{
                    history.push("/orders/confirmation");
                    // fireAlert("Warning", "Something went wrong, contact allec team for more details.")
                }
                
                
            } else{

                history.push("/orders/confirmation");
            }

        }).catch(e=>{console.log(e)})
    }

    const handleFavoriteSubmit = async (favoriteListIndex) => {

        setSubmitListIndex(favoriteListIndex)

        if(favoritesList[favoriteListIndex].company == null){
            fireAlert("Validation Error", "The company field is required");
            return;
        }else if(favoritesList[favoriteListIndex].location._id == null){
            fireAlert("Validation Error", "The location field is required");
            return;
        }else if (selectedProducts.filter(p => p.quantity > 0).length <= 0){
            fireAlert("Validation Error", "Please add the quantity for at least one product");
            return;
        }

        let isOrderValid = true
        let isFavSubmitValid = true
        let orderProducts = []
        let invalidQOHProducts = []
        let invalidPriceProducts = []
        let invalidMessage = ""

        selectedProducts.forEach(product => {
            
            if(product._id !== process.env.REACT_APP_FUEL_ID){
                if(product.amount > product.qtyOnHand){
                    isOrderValid = false
                    invalidQOHProducts.push(product.name)
                    // invalidMessage += "Not enough items to fulfill order, amount to order must be less than QoH. "
                }
            }
            if(product.listPrice || product.listPrice === ''){
                if(product.listPrice === '' || product.listPrice < 0){
                    isOrderValid = false
                    isFavSubmitValid = false
                    invalidPriceProducts.push(product.name)
                    // invalidMessage += "All unit price must be 0 or greater. "
                }
            } else{
                if(product.price === '' || product.price < 0){
                    isOrderValid = false
                    isFavSubmitValid = false
                    invalidPriceProducts.push(product.name)
                    // invalidMessage += "All unit price must be 0 or greater. "
                }
            }

            if(product.amount > 0){
                orderProducts.push(product)
            }
        })

        if(invoiceDate && shipDate && invoiceDate !== '' && shipDate !== ''){

        } else{
            isOrderValid = false
            isFavSubmitValid = false
            invalidMessage += "To submit, order must have an invoice date and a ship date. "
        }

        if(invalidQOHProducts.length > 0){
            invalidMessage += "Not enough items to fulfill order, amount to order must be less than QoH for the following items: " + invalidQOHProducts + ". "
        }  

        if(invalidPriceProducts.length > 0){
            invalidMessage += "All unit price must be 0 or greater for the following items: " + invalidPriceProducts + ". "
        }

        const data = {
            products: selectedProducts,
            location: favoritesList[favoriteListIndex].location,
            supplierId: favoritesList[favoriteListIndex].supplierId,
            companyId: favoritesList[favoriteListIndex].company._id,
            companyName: favoritesList[favoriteListIndex].company.businessName,
            comment: comment,
            customerPo: customerPo,
            shipVia: shipVia,
            invoiceDate: invoiceDate,
            shipDate: shipDate
        }

        setIsSubmitValid(isFavSubmitValid)
        if(isOrderValid){
            
            await favoriteOrderSubmit(accessToken, data).then(response =>{
                console.log("Response: ", response)
                let isAlertOpen = false
                
                dispatch(cartCountActions.getProductCount(accessToken, userId));
                if(listRecords.length > 1){
                    const listRecordIndexFound = listRecords.findIndex(curr => curr.favoriteListId === favoritesList[favoriteListIndex]._id)
                    
                    let newListRecords = [...listRecords]
                    if(listRecordIndexFound >= 0){
                        
                        newListRecords.splice(listRecordIndexFound, 1)
                    
                        setCasesQuantity(0)
                        setTotalOrderPrice(0)
                        setSelectedProducts([])
                        setInvoiceDate("")
                        setShipDate("")
                        setComment("")
                        setShipVia("")
                        setCustomerPo("")
                        setCategoryProductsObjectList([])
                        setListRecords(newListRecords)
                    }
                    let tempFavorites = [...favoritesList];
                    tempFavorites[favoriteListIndex].company = {};
                    tempFavorites[favoriteListIndex].locations = [];
                    tempFavorites[favoriteListIndex].location = {};
                    
                    setFavoritesList(tempFavorites)
                    setSelectedListId("")
                    setExpanded("")
    
                    if(newListRecords.length > 0){
                        fireAlert("Success", favoritesList[favoriteListIndex].name + " has been submitted. Other Lists still in progress.")
                        isAlertOpen = true
                    } else{
                        // history.push("/orders");
                        // fireAlert("Warning", "Something went wrong, contact allec team for more details.")
                    }
                    
                    
                } 

                if(response.data){
                    setIsSubmitValid(false)
                    setInvoice(response.data)
                    if(!isAlertOpen){
                        fireAlert("Success", "Order has been submited!")
                    }
                }
                
                // else{
    
                //     history.push("/orders");
                // }
                
            }).catch(e=>{console.log(e)})
        } else{
            fireAlert("Validation Error", invalidMessage);
        }
    }

    const handleForceSubmit = async () => {

        if(submitListIndex >= 0){
            const data = {
                products: selectedProducts,
                location: favoritesList[submitListIndex].location,
                supplierId: favoritesList[submitListIndex].supplierId,
                companyId: favoritesList[submitListIndex].company._id,
                companyName: favoritesList[submitListIndex].company.businessName,
                comment: comment,
                customerPo: customerPo,
                shipVia: shipVia,
                invoiceDate: invoiceDate,
                shipDate: shipDate
            }
    
            await favoriteOrderSubmit(accessToken, data).then(response =>{
                console.log("Response: ", response)
                let isAlertOpen = false

                dispatch(cartCountActions.getProductCount(accessToken, userId));
                console.log("List Records: ", listRecords)
                if(listRecords.length > 1){
                    const listRecordIndexFound = listRecords.findIndex(curr => curr.favoriteListId === favoritesList[submitListIndex]._id)
                    
                    let newListRecords = [...listRecords]
                    if(listRecordIndexFound >= 0){
                        
                        newListRecords.splice(listRecordIndexFound, 1)
                    
                        setCasesQuantity(0)
                        setTotalOrderPrice(0)
                        setSelectedProducts([])
                        setInvoiceDate("")
                        setShipDate("")
                        setComment("")
                        setShipVia("")
                        setCustomerPo("")
                        setCategoryProductsObjectList([])
                        setListRecords(newListRecords)
                    }
                    let tempFavorites = [...favoritesList];
                    tempFavorites[submitListIndex].company = {};
                    tempFavorites[submitListIndex].locations = [];
                    tempFavorites[submitListIndex].location = {};
                    
                    setFavoritesList(tempFavorites)
                    setSelectedListId("")
                    setExpanded("")
                    setIsSubmitValid(false)
    
                    if(newListRecords.length > 0){
                        fireAlert("Success", favoritesList[submitListIndex].name + " has been submitted. Other Lists still in progress.")
                        isAlertOpen = true
                    } else{
                        // history.push("/orders");
                        // fireAlert("Warning", "Something went wrong, contact allec team for more details.")
                    }

                } else{

                    // fireAlert("Warning", "Por que esta llegando aqui?")
                    // history.push("/orders");
                }
                
                if(response.data){
                    setIsSubmitValid(false)
                    setInvoice(response.data)
                    if(!isAlertOpen){
                        fireAlert("Success", "Order has been submited!")
                    }
                }
                
            }).catch(e=>{console.log(e)})
        } else{
            fireAlert("Submission error!", "Something went wrong on submit, contact Allec team for further assistance.");
        }
        
    }

    const handlePrintInvoice = async () => {

        const salesTaxCode = invoice.company[0].salesTaxCode
        let municipalTaxSum = 0
        let stateTaxSum = 0

        invoice.products.forEach(currProduct => {
            if(currProduct.isTaxable){
                if(salesTaxCode == "SALES PR"){
                    stateTaxSum += (currProduct.price * currProduct.amount) * 0.105
                    municipalTaxSum += (currProduct.price * currProduct.amount) * 0.01
                } else if(salesTaxCode == "10.5C"){
                    stateTaxSum += (currProduct.price * currProduct.amount) * 0.105
                }
            }
        })

        let totalAmount = 0
        const salesOrderProductsData = invoice.products.map(currItem => {
            let itemPrice = currItem.listPrice ? currItem.listPrice : currItem.price
            let itemTotalAmount = 0

            if(invoice.shipVia == "HOME" && currItem.isCatchWeight){
                const totalWeight = currItem.catchWeights?.length > 0 ? currItem?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0
                itemTotalAmount = (itemPrice * totalWeight)
                totalAmount += itemPrice * totalWeight;
            } else{
                itemTotalAmount = (itemPrice * currItem.amount)
                totalAmount += itemPrice * currItem.amount
            }
            
            return {
                name: currItem.name,
                category: currItem.category,
                sku: currItem.sku,
                unitPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                units: currItem.amount,
                itemPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                totalAmount: itemTotalAmount.toFixed(2),
                isCatchWeight: currItem.isCatchWeight,
                catchWeights: currItem.catchWeights ? currItem.catchWeights : [],
            }
        })
        const salesOrderData = {
            storeName: invoice.companyName,
            submissionDate: invoice.submissionDate,
            requestedDeliveryDate: invoice.shipDate,
            routeName: invoice.routes[0].name,
            orderNumber: invoice.orderNumber,
            salesRep: invoice.user[0].fullName,
            products: salesOrderProductsData,
            clientNumber: invoice.company[0].sageCustomerId,
            paymentTerm: invoice.company[0].dueDays,
            supplierName: invoice.supplier[0].entityName,
            supplierDescription: invoice.supplier[0].description,
            supplierPhone1: invoice.supplier[0].phoneNumber,
            supplierPhone2: invoice.supplier[0].phoneNumber2,
            supplierFax: invoice.supplier[0].fax,
            supplierAddress: `${invoice.supplier[0].physicalAddress}, ${invoice.supplier[0].physicalCity}, Puerto Rico, ${invoice.supplier[0].physicalZipcode}`,
            supplierDisclaimer: invoice.supplier[0].disclaimer,
            totalAmount: totalAmount.toFixed(2),
            sageInvoiceNumber: invoice.sageInvoiceNumber ? invoice.sageInvoiceNumber : false,
            salesTaxAmount: invoice.salesTaxAmount ? invoice.salesTaxAmount : false,
            totalCostTax: (totalAmount + stateTaxSum + municipalTaxSum).toFixed(2),
            comment: invoice.comment,
            stateTax: stateTaxSum,
            municipalTax: municipalTaxSum
        }
        
        const supplierTenantId = invoice.supplier[0].tenantId
        const urlPath = process.env.REACT_APP_WMS_API_HTTPS + (process.env.REACT_APP_ENV === 'DEV' ?
            `${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/store_order_endpoint_pdf` :
            `${supplierTenantId}.${process.env.REACT_APP_WMS_API_URL}/tenants/${supplierTenantId}/route_records/store_order_endpoint_pdf`
          );

        const response = await axios.post(urlPath, 
        // const response = await axios.post("http://localhost:8003/api/tenants/wahmey/route_records/store_order_endpoint_pdf", 
        salesOrderData, {
            responseType: 'arraybuffer', // Ensure the response type is set to arraybuffer
        });

        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new tab with the PDF
        const newTab = window.open(pdfUrl, '_blank');

        // handleClose()
    }

    const handleShipViaChange = (newShipVia) => {
        setShipVia(newShipVia)
        
        if(newShipVia == "HOME"){
            let newTotalCost = totalOrderPrice
            let newCategoryProductsObject = [...categoryProductsObjectList]
            let resultProducts = selectedProducts.map(item => {

                let currProduct = {...item}
                
                if(currProduct.isStaticCatchWeight && !Array.isArray(currProduct?.catchWeights)){
                    let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
                    let catchWeights = []

                    for(let i = 0; i < currProduct.amount; i++){
                        catchWeights.push(currProduct.staticCatchWeight)
                    }
    
                    const totalWeight = (catchWeights.reduce((total, currWeight) => total + parseFloat(currWeight)))
                    currProduct.catchWeights = catchWeights
                    newTotalCost += totalWeight * currProductPrice

                    const CategoryProductObjectIndexFound = newCategoryProductsObject.findIndex(currObject => currObject.category._id == currProduct.categoryId)
                    if(CategoryProductObjectIndexFound >= 0){
                        let currentCategoryObject = {...newCategoryProductsObject[CategoryProductObjectIndexFound]}
                        let currentCategoryObjectProductIndex = currentCategoryObject.products.findIndex(prod => prod._id == currProduct._id)
                        if(currentCategoryObjectProductIndex >= 0){
                            newCategoryProductsObject[CategoryProductObjectIndexFound].products[currentCategoryObjectProductIndex].catchWeights = catchWeights
                        }
                    }
                }  else if(!Array.isArray(currProduct?.catchWeights && currProduct.isCatchWeight)){
                    currProduct.catchWeights = []
                }

                return currProduct
            })

            setCategoryProductsObjectList(newCategoryProductsObject)
            setSelectedProducts(resultProducts)
            setTotalOrderPrice(newTotalCost)
        }
    }

    function handleCatchWeightClick(productId) {
        let newSelectedProducts = [...selectedProducts]

        console.log("Selected Products: ", newSelectedProducts)

        const currProductIndex = newSelectedProducts.findIndex(currProduct => currProduct._id == productId)
        let currProduct = {
            ...newSelectedProducts[currProductIndex],
            catchWeights: newSelectedProducts[currProductIndex]?.catchWeights ? [...newSelectedProducts[currProductIndex].catchWeights] : []
        };
        
        setCatchWeightProduct({...currProduct})
        // setSelectedProducts(newSelectedProducts)

        setOpenCW(true);
    }

    function handleAddCatchWeight() {
        
        if(catchWeightProduct.catchWeights && catchWeightProduct.catchWeights.length >= catchWeightProduct.amount){
            // console.log("Don't do Shit!")
        } else if(typeof catchWeight === "number" && catchWeight > 0){

            let newTotalCost = totalOrderPrice
            let newCategoryProductsObject = [...categoryProductsObjectList]
            let newSelectedProducts = [...selectedProducts]
            const currProductIndex = selectedProducts.findIndex(product => product._id == catchWeightProduct._id)
            let currProduct = {...selectedProducts[currProductIndex]}
            
            if(currProduct.catchWeights){
                currProduct.catchWeights.push(catchWeight)
            } else{
                currProduct.catchWeights = [catchWeight]
            }

            const CategoryProductObjectIndexFound = newCategoryProductsObject.findIndex(currObject => currObject.category._id == currProduct.categoryId)
            if(CategoryProductObjectIndexFound >= 0){
                let currentCategoryObject = {...newCategoryProductsObject[CategoryProductObjectIndexFound]}
                let currentCategoryObjectProductIndex = currentCategoryObject.products.findIndex(prod => prod._id == currProduct._id)
                if(currentCategoryObjectProductIndex >= 0){
                    newCategoryProductsObject[CategoryProductObjectIndexFound].products[currentCategoryObjectProductIndex].catchWeights = currProduct.catchWeights
                }
            }

            let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
            newTotalCost += catchWeight * currProductPrice

            newSelectedProducts[currProductIndex] = currProduct

            setCategoryProductsObjectList(newCategoryProductsObject)
            setSelectedProducts(newSelectedProducts)
            setCatchWeightProduct(currProduct)
            setTotalOrderPrice(newTotalCost)
            setCatchWeight("")
        }
    }

    function handleRemoveCatchWeight(cw) {
        
        if(catchWeightProduct.catchWeights && catchWeightProduct.catchWeights.length > 0){

            let newTotalCost = totalOrderPrice
            let newCategoryProductsObject = [...categoryProductsObjectList]
            let newSelectedProducts = [...selectedProducts]
            const currProductIndex = selectedProducts.findIndex(product => product._id == catchWeightProduct._id)
            let currProduct = {...selectedProducts[currProductIndex]}
            
            let catchWeightRemoved = 0
            const currrCatchWeightIndex = currProduct.catchWeights.findIndex(catchWeight => catchWeight == cw)
            if (currrCatchWeightIndex > -1) {
                catchWeightRemoved = currProduct.catchWeights[currrCatchWeightIndex]
                currProduct.catchWeights.splice(currrCatchWeightIndex, 1)
            }

            const CategoryProductObjectIndexFound = newCategoryProductsObject.findIndex(currObject => currObject.category._id == currProduct.categoryId)
            if(CategoryProductObjectIndexFound >= 0){
                let currentCategoryObject = {...newCategoryProductsObject[CategoryProductObjectIndexFound]}
                let currentCategoryObjectProductIndex = currentCategoryObject.products.findIndex(prod => prod._id == currProduct._id)
                if(currentCategoryObjectProductIndex >= 0){
                    newCategoryProductsObject[CategoryProductObjectIndexFound].products[currentCategoryObjectProductIndex].catchWeights = currProduct.catchWeights
                }
            }

            let currProductPrice = (currProduct.listPrice || currProduct.listPrice == '') ? currProduct.listPrice : currProduct.price
            newTotalCost -= cw * currProductPrice

            newSelectedProducts[currProductIndex] = currProduct

            setCategoryProductsObjectList(newCategoryProductsObject)
            setSelectedProducts(newSelectedProducts)
            setCatchWeightProduct(currProduct)
            setTotalOrderPrice(newTotalCost)
        }
    }


    const handleCompanyChange = async (element, favoritesIndex) => {
        const clickedCompany = companies.find(company => company._id == element.target.value);
        const clickedCompanyLocations = clickedCompany.locations;
        const tempFavorites = favoritesList;

        const favoriteListProducts = await fetchFavoriteListProducts(accessToken, favoritesList[favoritesIndex]._id, clickedCompany._id, favoritesList[favoritesIndex].supplierId, itemSearchText)
        
        const data = favoriteListProducts.data

        let newProducts = data.products
        let newCategoryProductsObjects = data.categoryProductsObjects

        if(selectedProducts.length > 0){
            for(const currProd of selectedProducts){

                const foundCategoryProductObjectIndex = newCategoryProductsObjects.findIndex(curr => curr.category._id == currProd.productCategory._id)
                if(foundCategoryProductObjectIndex >= 0){
                    const currCategoryProductObject = newCategoryProductsObjects[foundCategoryProductObjectIndex]

                    const categoryObjectProductFoundIndex = currCategoryProductObject.products.findIndex(curr => curr._id == currProd._id)
                    if(categoryObjectProductFoundIndex >= 0){
                        newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].quantity = currProd.quantity

                        if(currProd.listPrice || currProd.listPrice == ''){
                            newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].listPrice = currProd.listPrice
                        } else{
                            newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].price = currProd.price
                        }
                    }
                }
            }
        }

        tempFavorites[favoritesIndex].company = clickedCompany;
        tempFavorites[favoritesIndex].locations = clickedCompanyLocations;
        tempFavorites[favoritesIndex].products = newProducts;

        if(clickedCompanyLocations.length == 1){
            
            tempFavorites[favoritesIndex].location = clickedCompanyLocations[0]
        }
        
        setShipVia(clickedCompany.shipVia)
        setCategoryProductsObjectList(newCategoryProductsObjects)
        setTotalOrderPrice(0)
        setCasesQuantity(0)
        setSelectedProducts([])
        setFavoritesList([...tempFavorites])
    }
 
    const handleGetListProductsFilterChange = async (favoritesIndex, newSearchText) => {
        
        setItemSearchText(newSearchText)
        const tempFavorites = favoritesList;
        const currCompany = favoritesList[favoritesIndex].company;

        const favoriteListProducts = await fetchFavoriteListProducts(accessToken, favoritesList[favoritesIndex]._id, currCompany._id, favoritesList[favoritesIndex].supplierId, newSearchText)
        
        const data = favoriteListProducts.data
        
        let newProducts = data.products
        let newCategoryProductsObjects = data.categoryProductsObjects

        if(selectedProducts.length > 0){
            for(const currProd of selectedProducts){
                
                const foundCategoryProductObjectIndex = newCategoryProductsObjects.findIndex(curr => curr.category._id == currProd.productCategory[0]._id)
                
                if(foundCategoryProductObjectIndex >= 0){
                    const currCategoryProductObject = newCategoryProductsObjects[foundCategoryProductObjectIndex]

                    const categoryObjectProductFoundIndex = currCategoryProductObject.products.findIndex(curr => curr._id == currProd._id)
                    if(categoryObjectProductFoundIndex >= 0){
                        console.log("Suspiciious activity: ", newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex])
                        newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].quantity = currProd.quantity
                        
                        if(currProd.listPrice || currProd.listPrice == ''){
                            newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].listPrice = currProd.listPrice
                        } else{
                            newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].price = currProd.price
                        }
                    }
                }
            }
        }

        tempFavorites[favoritesIndex].products = newProducts;

        setCategoryProductsObjectList(data.categoryProductsObjects)
        setFavoritesList([...tempFavorites])
    }

    const handleLocationChange = (element, favoritesIndex) => {
        const clickedLocation = favoritesList[favoritesIndex].locations.find(location => location._id == element.target.value);

        const tempFavorites = favoritesList;
        tempFavorites[favoritesIndex].location = clickedLocation;

        setFavoritesList([...tempFavorites])

    }

    const handleDefaultDateChange = (e) => {
        setDefaultDate(e.target.value)
        setInvoiceDate(e.target.value)
        setShipDate(e.target.value)
    }

    const handleInvoiceDateChange = (e) => {
        if(activeTenant == process.env.REACT_APP_WAHMEY_TENANT){
            setShipDate(e.target.value)
        }

        setInvoiceDate(e.target.value)
    }

    const handleShipDateChange = (e) => {
        if(activeTenant == process.env.REACT_APP_WAHMEY_TENANT){
            setInvoiceDate(e.target.value)
        }

        setShipDate(e.target.value)
    }

    const handleDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle("");
        setAlertText("");
    }

    function handleCloseCW() {
        setOpenCW(false);
        setCatchWeightProduct(null)
        setCatchWeight("")
    }

    const fireAlert = (title, text) => {
        setAlertTitle(title);
        setAlertText(text);
        setAlertOpen(true);
    }

    const handleAccordionChange = (panel, fl, listIndex) => async (event, newExpanded) => {

        // TODO: Fetch companies with approved locations for selected favorites list supplier.
        let tempFavorites = [...favoritesList];
        let newCategoryProductsObjects = [];
        if(newExpanded){
            setIsPanelChangeDisabled(true)

            await fetchCompanies(accessToken, fl.supplierId).then(async response =>{
                const fetchedCompanies = response.data
                setCompanies(fetchedCompanies)
                setIsPanelChangeDisabled(false)

                if(fl.defaultCompanyId && fl.defaultCompanyId !== ""){
                    const foundCompany = fetchedCompanies.find(company => company._id == fl.defaultCompanyId);

                    if(foundCompany){
                        tempFavorites[listIndex].company = foundCompany
                        tempFavorites[listIndex].locations = foundCompany.locations
                    }

                    if(fl.defaultLocationId && fl.defaultLocationId !== ""){
                        const foundLocation = foundCompany.locations.find(loc => loc._id == fl.defaultLocationId);
                        if(foundLocation){
                            tempFavorites[listIndex].location = foundLocation
                        }
                    }

                    // -------------

                    const favoriteListProducts = await fetchFavoriteListProducts(accessToken, favoritesList[listIndex]._id, foundCompany._id, favoritesList[listIndex].supplierId, itemSearchText)
                    
                    const data = favoriteListProducts.data

                    let newProducts = data.products
                    newCategoryProductsObjects = data.categoryProductsObjects

                    if(selectedProducts.length > 0){
                        for(const currProd of selectedProducts){

                            const foundCategoryProductObjectIndex = newCategoryProductsObjects.findIndex(curr => curr.category._id == currProd.productCategory._id)
                            if(foundCategoryProductObjectIndex >= 0){
                                const currCategoryProductObject = newCategoryProductsObjects[foundCategoryProductObjectIndex]

                                const categoryObjectProductFoundIndex = currCategoryProductObject.products.findIndex(curr => curr._id == currProd._id)
                                if(categoryObjectProductFoundIndex >= 0){
                                    newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].quantity = currProd.quantity

                                    if(currProd.listPrice || currProd.listPrice == ''){
                                        newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].listPrice = currProd.listPrice
                                    } else{
                                        newCategoryProductsObjects[foundCategoryProductObjectIndex].products[categoryObjectProductFoundIndex].price = currProd.price
                                    }
                                }
                            }
                        }
                    }

                    tempFavorites[listIndex].products = newProducts;
                    
                    setShipVia(foundCompany.shipVia)
                    setCategoryProductsObjectList(newCategoryProductsObjects)
                    setTotalOrderPrice(0)
                    setCasesQuantity(0)
                    setSelectedProducts([])
                    setFavoritesList([...tempFavorites])
                }
                
            }).catch(e=>{console.log(e)})
        }

        
        // tempFavorites[listIndex].company = {};
        // tempFavorites[listIndex].locations = [];
        // tempFavorites[listIndex].location = {};
        if(listRecords.length < 1){

            const currList = {
                favoriteListId: fl._id,
                selectedProducts: selectedProducts,
                comment: comment,
                customerPo: customerPo,
                shipVia: shipVia,
                invoiceDate: invoiceDate,
                shipDate: shipDate,
                casesQuantity: casesQuantity,
                totalOrderPrice: totalOrderPrice,
                categoryProductsObjectList: categoryProductsObjectList
            }

            setListRecords([currList])
            setSelectedListId(fl._id)
            setExpanded(newExpanded ? panel : "")

        } else{

            
            let newListRecords = [...listRecords]
            if(selectedListId !== ''){
                const currList = {
                    favoriteListId: selectedListId,
                    selectedProducts: selectedProducts,
                    comment: comment,
                    customerPo: customerPo,
                    shipVia: shipVia,
                    invoiceDate: invoiceDate,
                    shipDate: shipDate,
                    casesQuantity: casesQuantity,
                    totalOrderPrice: totalOrderPrice,
                    categoryProductsObjectList: categoryProductsObjectList
                }
        
                
                const listIndexFound = newListRecords.findIndex(curr => curr.favoriteListId === selectedListId)
        
                if(listIndexFound >= 0){
                    newListRecords[listIndexFound] = currList
                } else{
                    newListRecords.push(currList)
                }
            }
    
            const currListIndex = newListRecords.findIndex(curr => curr.favoriteListId === fl._id)
            if(currListIndex >= 0){
                const currList = newListRecords[currListIndex]
                setCasesQuantity(currList.casesQuantity)
                setTotalOrderPrice(currList.totalOrderPrice)
                setSelectedProducts(currList.selectedProducts)
                setInvoiceDate(currList.invoiceDate)
                setShipDate(currList.shipDate)
                setComment(currList.comment)
                setShipVia(currList.shipVia)
                setCustomerPo(currList.customerPo)
                setCategoryProductsObjectList(currList.categoryProductsObjectList)
            } else{
                setCasesQuantity(0)
                setTotalOrderPrice(0)
                setSelectedProducts([])
                setInvoiceDate("")
                setShipDate("")
                setComment("")
                setShipVia("")
                setCustomerPo("")
                setCategoryProductsObjectList(newCategoryProductsObjects)

                newListRecords.push({
                    favoriteListId: fl._id,
                    selectedProducts: [],
                    comment: "",
                    customerPo: "",
                    shipVia: "",
                    invoiceDate: "",
                    shipDate: "",
                    casesQuantity: 0,
                    totalOrderPrice: 0,
                    categoryProductsObjectList: []
                })
            }
    
            setSelectedListId(fl._id)
            setExpanded(newExpanded ? panel : "")
            setFavoritesList(tempFavorites)
            setListRecords(newListRecords)
            
            setSearchText("")
            setDefaultDate("")
        }
        
    };

    const handlePageChange = (pageNumber) =>{

        fetchFavorites(searchName, pageNumber, paginationOptions.sizePerPage, userFilter)

        setSelectedListId("")
        setExpanded("")
    }

    const handlePageSizeChange = (pageSize) =>{
        console.log("Element: ", pageSize)
        fetchFavorites(searchName, paginationOptions.page, pageSize, userFilter)
    }

    const handleUserFilter = (element) => {

        const clickedTenantUser = tenantUsers.find(tenantUser => tenantUser._id == element.target.value)

        // console.log("Clicked user: ", clickedTenantUser)

        if(clickedTenantUser) {
            setUserFilter(clickedTenantUser._id)

            fetchFavorites(searchName, 1, paginationOptions.sizePerPage, clickedTenantUser._id)

        } else{
            setUserFilter("")
            
            fetchFavorites(searchName, 1, paginationOptions.sizePerPage, "")
        }
    }

    return (
        <>
            <div>
                <div className="row justify-content-center">
                    <div className="col-12 mb-5">
                        <div className="row justify-content-between">
                            <h1 className={'title'}>Favorites</h1>
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={newFavoriteList}>
                                Create New Favorites Lists
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="d-flex flex-wrap col-3">
                        <FormControl variant="filled" style={{ width: "100" }}>
                            <InputLabel>User Filter</InputLabel>
                            <Select
                                MenuProps={{ autoFocus: false }}
                                value={userFilter ? userFilter : ""}
                                style={{ minWidth: "200px" }}
                                input={<FilledInput name="userFilter" id="userFilter"
                                    onChange={(element) => {
                                        handleUserFilter(element);
                                        // setCompany(companies.find(company => company._id == element.target.value))
                                        // setLocations(companies.find(company => company._id == element.target.value).locations)
                                    } }
                                    type="text"
                                    className="form-control form-control-solid h-auto mb-5" />}>

                                <MenuItem key={0} value={""}>All</MenuItem>
                                {tenantUsers.map((tenantUser,index) =>(
                                    <MenuItem key={tenantUser._id} value={tenantUser._id}>{tenantUser.fullName}</MenuItem>
                                ))

                                }

                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row justify-content-between mb-4">
                    <Formik
                        initialValues={{searchText:""}}
                        onSubmit={(values) =>{
                            setSearchName(values.searchText)
                            console.log("SearchName: ", values.searchText)
                            
                            fetchFavorites(values.searchText, 1, paginationOptions.sizePerPage, userFilter)
                            // Fetch Favorites List with new text filter:
                            
                        }}>
                        {({values, handleSubmit, handleBlur, handleChange, setFieldValue})=>(
                            <form onSubmit={handleSubmit} className="form form-label-right w-100">
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="searchText"
                                            placeholder="Search"
                                            onBlur={handleBlur}
                                            value={values.searchText}
                                            onChange={(e) =>{
                                                setFieldValue("searchText",e.target.value);
                                                handleSubmit();
                                            }}
                                        />
                                        <small className="form-text text-muted">
                                            <b>Search</b> by List Name
                                        </small>
                                    </div>
                                    <button
                                        id="addLocationButton"
                                        type="button"
                                        onClick={() => {
                                            setFieldValue("searchText",'')
                                            handleSubmit();
                                        }}
                                        // disabled={formik.isSubmitting}
                                        className="btn btn-primary font-weight-bold px-4 mx-4"
                                        >
                                            <span>Clear</span>
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                {
                    isTableReady ? 
                    <div className="col-12 my-5">
                        {favoritesList.map((fl, listIndex) => (
                            <>
                                <ExpansionPanel square expanded={expanded === 'panel' + listIndex} onChange={handleAccordionChange('panel' + listIndex, fl, listIndex)}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls={'panel-content-' + listIndex}
                                        id={'panel-header-' + listIndex}
                                        disabled={isPanelChangeDisabled}
                                    >
                                        <Typography className={classes.heading}>
                                            {fl.name}
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>{fl.supplier[0].entityName}</Typography>
                                        {
                                            isPanelChangeDisabled ? 
                                                <div className={classes.headingSpinner}>
                                                    <span className="spinner spinner-primary spinner-lg"></span>
                                                </div>
                                            :
                                                null
                                        }
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div className="col-12 my-5">
                                            <Card>
                                                <Card.Header title="Favorites">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h4>Must Select Customer</h4>
                                                            <div>
                                                                <FormControl variant="filled" style={{width: "100%"}}>
                                                                    {
                                                                        isSeller ? 
                                                                            <InputLabel>Customer</InputLabel>
                                                                            :
                                                                            <InputLabel>Company</InputLabel>
                                                                    }
                                                                    <Select
                                                                        MenuProps={{ autoFocus: false }}
                                                                        value={fl.company._id ? fl.company._id : ""}
                                                                        style={{minWidth:"200px"}}
                                                                        input={<FilledInput name="company" id="company"
                                                                            onChange={(element) =>{
                                                                                handleCompanyChange(element, listIndex)
                                                                            }}
                                                                            type="text"
                                                                            className="form-control form-control-solid h-auto mt-3"
                                                                        />}>
                                                                            <ListSubheader>
                                                                                <TextField
                                                                                size="small"
                                                                                // Autofocus on textfield
                                                                                autoFocus
                                                                                placeholder="Type to search..."
                                                                                fullWidth
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <SearchIcon />
                                                                                    </InputAdornment>
                                                                                    )
                                                                                }}
                                                                                onChange={(e) => setSearchText(e.target.value)}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key !== "Escape") {
                                                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                                                    e.stopPropagation();
                                                                                    }
                                                                                }}
                                                                                />
                                                                            </ListSubheader>
                                                                            {displayedOptions.map((companyItem,index) =>(
                                                                                <MenuItem key={companyItem._id} selected={index === 0 } value={companyItem._id}>{companyItem.businessName + " (Credit: $" + (companyItem.applications[0].creditInformation.authorizedCredit - companyItem.balance) + ")" +
                                                                                " (Route: " + (companyItem?.routes.length > 0 ? companyItem?.routes[0].name : "NONE") + ")"}</MenuItem>
                                                                            ))

                                                                            }

                                                                        {/* {fl?.companies?.map((companyItem,index) =>(
                                                                            <MenuItem selected={index === 0 } value={companyItem._id}>{companyItem.businessName}</MenuItem>
                                                                        ))} */}
                                                                    </Select>
                                                                </FormControl>
                                                                <div className="my-3">
                                                                    <FormControl variant="filled" style={{width: "100%"}}>
                                                                        <InputLabel>Location</InputLabel>
                                                                        <Select
                                                                            value={fl.location._id ? fl.location._id : ""}
                                                                            style={{minWidth:"200px"}}
                                                                            input={<FilledInput name="location" id="location"
                                                                                onChange={(element) =>{
                                                                                    handleLocationChange(element, listIndex)
                                                                                }}
                                                                                type="text"
                                                                                className="form-control form-control-solid h-auto"
                                                                            />}>
                                                                            {fl?.locations?.map((locationItem,index) =>(
                                                                                <MenuItem selected={index === 0 } value={locationItem._id}>{locationItem.locationName}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                {
                                                                    activeTenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                                                                        <div className="form-group fv-plugins-icon-container">
                                                                            <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                                                            <Input
                                                                                type="date"
                                                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                                name="personalBirthDate"
                                                                                value={defaultDate}
                                                                                onChange={(e) => handleDefaultDateChange(e)}
                                                                            />
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 row justify-content-end">
                                                            <div>
                                                                <Link className="btn btn-light" href={`/orders-favorites/${fl._id}/edit`}>
                                                                    Edit
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <TextField
                                                            size="small"
                                                            // Autofocus on textfield
                                                            autoFocus
                                                            placeholder="Type to search..."
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                                )
                                                            }}
                                                            onChange={(e) => handleGetListProductsFilterChange(listIndex, e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key !== "Escape") {
                                                                // Prevents autoselecting item while typing (default Select behaviour)
                                                                e.stopPropagation();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        categoryProductsObjectList.length > 0 ?
                                                            categoryProductsObjectList.map(currObject => 
                                                                <CategoryCollapseItemList 
                                                                    category={currObject.category}
                                                                    categoryPoducts={currObject.products}
                                                                    listIndex={listIndex}
                                                                    handlePriceChange={handlePriceChange}
                                                                    handleChangeQuantity={handleChangeQuantity}
                                                                    handleCatchWeightClick={handleCatchWeightClick}
                                                                    shipVia={shipVia}
                                                                />
                                                            )
                                                        :
                                                            null
                                                    }
                                                    
                                                    
                                                    <div className="row justify-content-center">
                                                        {
                                                            selectedProducts.length > 0 ?
                                                                <span className="label label-lg label-light-info label-inline mt-5 mr-2">{`Total items: ${selectedProducts.length}`}</span>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            casesQuantity > 0 ?
                                                                <span className="label label-lg label-light-info label-inline mt-5 ml-2">{`Total cases: ${casesQuantity}`}</span>
                                                                        :
                                                                null
                                                        }
                                                        {
                                                            selectedProducts.length > 0 ?
                                                                <span className="label label-lg label-light-info label-inline mt-5 ml-2">{`Total price: $${totalOrderPrice.toFixed(2)}`}</span>
                                                                        :
                                                                null
                                                        }
                                                    </div>
                                                        
                                                </Card.Body>
                                                <Card.Footer>
                                                    <div className="row justify-content-between mb-3">
                                                        <div className="d-flex flex-wrap">
                                                            <button className="btn btn-primary" onClick={() => addToCart(listIndex)}>Add To Cart</button>
                                                        </div>
                                                        <div className="d-flex">
                                                            <button className="btn btn-primary" onClick={() => setIsSubmitExpanded(oldIsSubmitExpanded => !oldIsSubmitExpanded)}>{ isSubmitExpanded ? "Close" : "Submit"}</button>
                                                        </div>
                                                    </div>
                                                    <Collapse in={isSubmitExpanded} timeout="auto" unmountOnExit>
                                                        <div>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={6}>
                                                                    <div className="form-group fv-plugins-icon-container">
                                                                        <label htmlFor={"orderComent"}>Comment</label>
                                                                        <textarea
                                                                            value={comment}
                                                                            onChange={(e) => setComment(e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            placeholder={"Comment"}
                                                                            rows="3"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group fv-plugins-icon-container">
                                                                        <label htmlFor={"customerPo"}>Customer PO</label>
                                                                        <input
                                                                            value={customerPo}
                                                                            onChange={(e) => setCustomerPo(e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            placeholder={"Customer PO"}
                                                                            type="text"
                                                                        />
                                                                    </div>
                                                                    <FormControl variant="filled" style={{ width: "100%" }}>
                                                                        <InputLabel>Ship Via</InputLabel>
                                                                        <Select
                                                                            MenuProps={{ autoFocus: false }}
                                                                            value={shipVia}
                                                                            style={{ minWidth: "200px" }}
                                                                            input={<FilledInput name="shipVia" id="shipVia"
                                                                                onChange={(e) => {
                                                                                    handleShipViaChange(e.target.value);
                                                                                } }
                                                                                type="text"
                                                                                className="form-control form-control-solid h-auto" />}>

                                                                            <MenuItem selected key={fl?.company?.shipVia} value={fl?.company?.shipVia}>{fl?.company?.shipVia == "Our Truck" ? "Delivery" : fl?.company?.shipVia == "HOME" ? "Pick Up" : fl?.company?.shipVia}</MenuItem>
                                                                            {
                                                                                fl.supplier[0].shipViaOptions.map((option,index) =>(
                                                                                    fl?.company?.shipVia == option ?
                                                                                    null
                                                                                    :
                                                                                    <MenuItem key={option} value={option}>{option == "Our Truck" ? "Delivery" : option == "HOME" ? "Pick Up" : option}</MenuItem>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <div className="form-group fv-plugins-icon-container">
                                                                        <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                                                        <Input
                                                                            type="date"
                                                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                            name="personalBirthDate"
                                                                            value={invoiceDate}
                                                                            onChange={(e) => handleInvoiceDateChange(e)}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group fv-plugins-icon-container">
                                                                        <label htmlFor={"shipDate"}>Ship Date</label>
                                                                        <Input
                                                                            type="date"
                                                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                            name="personalBirthDate"
                                                                            value={shipDate}
                                                                            onChange={(e) => handleShipDateChange(e)}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="row col-12 justify-content-center mt-5">
                                                            <button type="button" className="btn btn-primary" onClick={() => handleFavoriteSubmit(listIndex)}>Submit</button>
                                                        </div>
                                                    </Collapse>
                                                </Card.Footer>
                                            </Card>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                            </>
                        ))}
                    </div>
                    :
                    <div className="row justify-content-center">
                        <span className="spinner spinner-primary spinner-lg"></span>
                    </div>

                }
                
                <div className="row justify-content-center">
                    <div className="col-12">
                        <FormControl variant="filled" style={{ width: "100%" }}>
                            <Select
                                MenuProps={{ autoFocus: false }}
                                value={paginationOptions.sizePerPage}
                                style={{ minWidth: "200px" }}
                                input={<FilledInput name="pageSize" id="pageSize"
                                    onChange={(element) => {
                                        handlePageSizeChange(element.target.value);
                                    } }
                                    type="text"
                                    className="form-control form-control-solid h-auto" 
                                />}
                            >
                                <MenuItem key={"pageSize10"} value={10}>{"10"}</MenuItem>
                                <MenuItem key={"pageSize20"} value={20}>{"20"}</MenuItem>
                                <MenuItem key={"pageSize50"} value={50}>{"50"}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={paginationOptions.page}
                        itemsCountPerPage={paginationOptions.sizePerPage}
                        totalItemsCount={paginationOptions.totalSize}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(this)}
                    />
                </div>
            </div>
            <div>
                <Dialog
                    open={alertOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            OK
                        </Button>
                        { invoice?.shipVia == "HOME" ?
                            <Button onClick={() => handlePrintInvoice()} color="primary" autoFocus>
                                Print Invoice
                            </Button>
                            :
                            null
                        }
                        {
                            isBuyerAdmin && isSubmitValid ? 
                            <Button onClick={() => handleForceSubmit()} color="primary" autoFocus>
                                Force Submit
                            </Button>
                            :
                            null
                        }
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openCW}
                    onClose={handleCloseCW}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Catch Weight Input"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="d-flex flex-column text-md-left">
                                <span><span className="mb-1 font-weight-bolder">Product: </span> {catchWeightProduct?.name}</span>
                                <span><span className="mb-1 font-weight-bolder">Sku: </span> {catchWeightProduct?.sku}</span>
                                <span><span className="mb-1 font-weight-bolder">Left to Add: </span> {(catchWeightProduct?.amount - (catchWeightProduct?.catchWeights?.length ? catchWeightProduct?.catchWeights?.length : 0))}</span>
                            </div>
                        </DialogContentText>   

                        Weight: { (catchWeightProduct?.catchWeights.length > 0 ? catchWeightProduct?.catchWeights?.reduce((total, currWeight) => total + parseFloat(currWeight)) : 0).toFixed(2)}
                        <div className="row justify-content-between py-8 px-20 py-md-10 px-md-0">
                            <div className="d-flex">
                                <input
                                    value={catchWeight}
                                    onChange={(e) => setCatchWeight(Number(e.target.value))}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleAddCatchWeight()
                                        }
                                    }}
                                    className={`form-control form-control-solid h-auto py-2 px-6`}
                                    type="number"
                                    min="0"
                                    style={{minWidth:"125px"}}
                                />
                                <Button onClick={() => handleAddCatchWeight()} color="primary" autoFocus>
                                    Add
                                </Button>
                            </div>
                        </div>
                        
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className='border-bottom'>
                                        <th className="text-center font-weight-bold text-muted text-uppercase">Product</th>
                                        <th className="text-center font-weight-bold text-muted text-uppercase">Weight</th>
                                        <th className="text-center font-weight-bold text-muted text-uppercase">Remove </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {   catchWeightProduct && catchWeightProduct.catchWeights?
                                            catchWeightProduct.catchWeights.map(cw => (
                                                <tr className="font-weight-boldest border-bottom-0">
                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                        {catchWeightProduct?.name + " (" + catchWeightProduct?.sku + ")"}
                                                    </td>
                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                        {cw}
                                                    </td>
                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                        <InputAdornment position="end">
                                                            <IconButton  onClick={() => handleRemoveCatchWeight(cw)}
                                                            >
                                                            <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                            </span>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </td>
                                                </tr>
                                            ))
                                        :
                                        null
                                    }
                                </tbody>
                            </table>

                        </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseCW} color="primary" autoFocus>
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default injectIntl(OrderFavoritesTable);
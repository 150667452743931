import {makeStyles} from '@material-ui/core/styles';
import React, {useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import { fetchTenantReport } from "../Reports/ReportsCrud"
import { useLocation } from "react-router";

import Iframe from "react-iframe";
import jwt from "jsonwebtoken";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    paper: {
        padding: theme.spacing(2, 2),
        // height: 140,
        // width: 300,
    },
    modalPaper: {
        position: 'absolute',
        // width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function ReportsPage(props) {
    // const initialData = {
    //     data: [],
    //     totalCount: 0,
    //     pageSize: 3
    // }

    // const {intl} = props;
    // const classes = useStyles();
    // const [data, setData] = useState(initialData)
    // const [companies, setCompanies] = useState([])
    // const [isCompaniesFetched, setIsCompaniesFetched] = useState(false)
    // const [locations, setLocations] = useState([])
    // const [isLocationDetails, setIsLocationDetails] = useState(false)
    // const [isLocationsPage, setIsLocationsPage] = useState(false)
    // const [open, setOpen] = useState(false);

    const [report, setReport] = useState(null)
    const [isReportFound, setIsReportFound] = useState(false);
    const [iFrameUrl, setiFrameUrl] = useState("")

    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user,
            accessToken: auth.authToken,
        }));

    const location = useLocation();
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];

    useEffect(() => {

        fetchTenantReport(accessToken, lastSegment).then(response => {
            console.log("Response: ", response)
            const currReport = response.data.data
            
            if(currReport){
                const METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL
                const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY

                // let payload = {
                //     resource: { dashboard: 620 },
                //     params: {},
                //     exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
                // };

                console.log("Full Name: ", user)
                let params = {}
                if(currReport.parameters){
                    if(currReport.parameters.length > 0){
                        for(let i = 0; i < currReport.parameters.length; i++){
                            if(currReport.parameters[i] == 'seller'){
                                params.seller = user.name
                            }
                        }
                    }
                }

                let payload = {
                    resource: { dashboard: currReport.dashboardId },
                    params: params,
                    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
                }

                const token = jwt.sign(payload, METABASE_SECRET_KEY);

                setiFrameUrl(METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true")
                setReport(currReport)
                setIsReportFound(true)
            }
            
      
          }).catch(error => console.log(error))
    }, [ location ])

    return (
        <>
            { isReportFound && report ? 
        
                <Card>
                    <CardHeader title={report.name}>
                        <CardHeaderToolbar>
                            
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <div className="col-12">
                            <Iframe
                                url={iFrameUrl}
                                width="100%"
                                height="800px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                            />
                        </div>
                    </CardBody>
                </Card>
                :
                null
            }
            
        </>
       
    );
}

export default injectIntl(ReportsPage);